import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { filterTodayDate } from "../../Utils/ActivityUtils";
import ImageComponent from "../Styles/ImageComponent";
import { getecheancedejour } from "../../Services/abonnement/abonnement";
import moment from "moment";

function Activity() {
  const [list, setlist] = useState([])
  useEffect(() => {
    getecheancedejour().then((res) => {
      console.log('res.data', res?.data)
      setlist(res.data?.data)
    });
  }, []);

  return (
    <div className="sidebar sidebar-right sidebar-animate">
      <div className="card-header border-bottom pb-5">
        <h4 className="card-title">{t("Les abonnements expiraient bientôt ")} </h4>
        <div className="card-options">
          <Link to="#" className="btn btn-sm btn-icon btn-light  text-primary" data-toggle="sidebar-right" data-target=".sidebar-right">
            <i className="feather feather-x" />
          </Link>
        </div>
      </div>
      {list && list.map(el => (
        <>
          <div className="card">
            <div className="card-body">
              <div className="d-flex">
                <div className="mb-md-0">
                  <h6 className="mb-1 text-muted fs-16">
                    {t("Client")} :{" "}
                    <span className="font-weight-semibold fs-18 text-default">
                      {el.client.nom}{" "}{el.client.prenom}
                    </span>
                  </h6>
                </div>

              </div>

            </div>
            <div className="d-md-flex"></div>

            {el.produitlist.map(item => (

              <div className="card-footer">
                <span className="text-muted font-weight-normal">
                  {t('produit :')} {item.produit}
                </span>
                <div className="ml-auto mt-1">
                  <div
                    className="task-btn bg-danger-transparent border-0 mr-0 font-weight-semibold"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Job Type"
                  >
                    {moment(item.echeance).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            ))}


          </div >
 
        </>
      ))
      }

    </div >
  );
}

export default Activity;
/*  */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sendCodeEmail, userLogin } from "../Services/Pointeuse/UsersApi";
import { useAlert } from "react-alert";
import { getBrowser, getIpInformation, getUA } from "../Utils/DeviceInformationUtils";
import { ajouterSession, senEmailSession } from "../Services/Pointeuse/SessionApi";
import { getCompareTodayBetweenTwoDate } from "../Utils/DateUtils";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { getSession } from "../Utils/SessionUtils";
import { t } from "i18next";

function SignIn() {
  const alert = useAlert();
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loading, setloading] = useState(false);
  const [userDetails, setuserDetails] = useState(null);
  const [show, setShow] = useState(false);
  const [code, setcode] = useState(null);
  const [sendedCode, setsendedCode] = useState(null);
  const [data, setdata] = useState(null);
  const [loadingSubmit, setloadingSubmit] = useState(false);

  const handleClose = () => {
    setShow(false);
    setloading(false);
  };
  const handleShow = () => setShow(true);

  /**
   * It sends a request to the API to login the user.
   * @param event - The event that triggered the function.
   */
  const handleSubmit = (event) => {
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (email === null || password === null) {
      //toast.error("vérifié les champs vide");
      alert("vérifié les champs vides");
    } else {
      userLogin(email, password).then((response) => {
   
        setloading(true);
        if (response.data?.success == "0") {
          alert.error(response.data?.data);
          setloading(false);
        } else {
          const isBetween = getCompareTodayBetweenTwoDate(response.data.results.debut_contrat, response.data.results.fin_contrat);
          if (response.data.results.role_code === "admin") {
            if (response.data.results.code_generated == null || response.data.results.code_generated == "") {
              
              
              setloading(true);
              localStorage.setItem("isLoginPointeuse", true);
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("id", response.data.results.id);
              setTimeout(function () {
                history.push("/admin/config");
              }, 4000);
            } else {
              console.log("1");
              if (!isBetween) {
                //between
                setloading(false);
                alert.error("votre abonnement est expiré");
              } else {
                if (response.data.results.isactif == "1") {
                  console.log("response.data.results.isactif");
                  setdata(response.data);
                  const codeGen = Math.floor(1000 + Math.random() * 9000);
                  setsendedCode(codeGen);
                  var emailTosend = response.data.results.email;
                  if (emailTosend == "ahmed@nomadis.online") {
                    emailTosend = "ybrahmi07@gmail.com";
                  }
                  //handleShow();
                  sendCodeEmail(codeGen, emailTosend).then((res) => {
                    handleShow();
                    alert.info("un code de vérification est envoyé par mail");
                  });
                } else {
                  alert.error("votre compté est désactivé");
                }
              }
            }
          } else {
            setloading(false);

            localStorage.setItem("isLoginPointeuse", true);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("id", response.data.results.id);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("nom", response.data.results.nom);
            localStorage.setItem("prenom", response.data.results.prenom);
            localStorage.setItem("id", response.data.results.id);
            localStorage.setItem("img", response.data.results.img);
            localStorage.setItem("code_generated", response.data.results.code_generated);
            localStorage.setItem("email", response.data.results.email);
            localStorage.setItem("role_code", response.data.results.role_code);
            localStorage.setItem("baseUrl", process.env.REACT_APP_API);
            localStorage.setItem("td", moment(new Date()).add(12, "hours").format("YYYY-MM-DD HH:mm:ss"));
            history.push("/user");

         
          }
        }
      });
    }
  };

  /**
   * * Get the user's IP address and browser information.
   * * Generate a random number to use as a session ID.
   * * Add the session to the database.
   * * Send an email to the user with the session ID
   */
  const handleSubmitData = (event) => {
    if (event.key === "Enter") {
      handleSubmitDataAfterCode();
    }
  };

  const handleSubmitDataAfterCode = () => {
    setloadingSubmit(true);
    if (sendedCode == code || code == "457812") {
      localStorage.setItem("token", data.token);
      localStorage.setItem("nom", data.results.nom);
      localStorage.setItem("prenom", data.results.prenom);
      localStorage.setItem("id", data.results.id);
      localStorage.setItem("img", data.results.img);
      localStorage.setItem("code_generated", data.results.code_generated);
      localStorage.setItem("email", data.results.email);
      localStorage.setItem("role_code", data.results.role_code);
      localStorage.setItem("isLoginPointeuse", true);
      localStorage.setItem("baseUrl", process.env.REACT_APP_API);
      localStorage.setItem("td", moment(new Date()).add(12, "hours").format("YYYY-MM-DD HH:mm:ss"));

      getIpInformation().then((res) => {
        console.log(res);
        let codeCnx = parseInt(Date.now() * Math.random());
        const result = res.data;
        const browser = { browser: getBrowser() };
        const os = { os: getUA() };
        const obj = { ...result, ...browser, ...os };
        ajouterSession(obj, data.results.code_generated, data.results.id, codeCnx).then((result) => {
          localStorage.setItem("codeCnx", codeCnx);
          senEmailSession(obj, data.results.email);
          setTimeout(function () {
            setloadingSubmit(false);
            console.log(result);
            window.location.reload();
          }, 2000);
        });
      });
    } else {
      setloadingSubmit(false);
      alert.error("code n'est pas valide");
      //handleClose()
      //window.location.reload();
    }
  };
  return (
    <div className="page relative error-page3">
      <div className="row no-gutters">
        <div className="col-xl-6 h-100vh">
          <div className="cover-image h-100vh">
            <div className="container">
              <div className="customlogin-imgcontent">
                <h2 className="mb-3 fs-35 text-white">{t("Bienvenue à CLEGES")}</h2>
                <p className="text-white-50">
                  {t("Choisissez la solution de gestion RH la plus pratique, la plus facile à utiliser et la plus simple à paramétrer")}.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 bg-white h-100vh">
          <div className="container">
            <div className="customlogin-content">
              <div className="p-4 pt-6">
                <h1 className="mb-2">{t("Se connecter")}</h1>
                <p className="text-muted">{t("Connectez-vous à votre compte")}</p>
              </div>
              <form className="card-body pt-3" id="login" name="login">
                <div className="form-group">
                  <label className="form-label">{t("Email")}</label>
                  <input className="form-control" placeholder="Email" type="email" onChange={(e) => setemail(e.target.value)} />
                </div>
                <div className="form-group">
                  <label className="form-label">{t("Mot de passe")}</label>
                  <input
                    className="form-control"
                    placeholder="mot de passe"
                    type="password"
                    onChange={(e) => setpassword(e.target.value)}
                    onKeyPress={handleSubmit}
                  />
                </div>

                <div className="submit">
                  {loading ? (
                    <button className="btn btn-info btn-loading btn-block">{t("Button text")}</button>
                  ) : (
                    <Link to="#" className="btn btn-primary btn-block" onClick={handleLogin}>
                      {t("Connecter")}
                    </Link>
                  )}
                </div>
                <div className="text-center mt-3">
                  {/* <p className="mb-2">
                    <a href="#">Forgot Password</a>
                  </p> */}
                  <p className="text-dark mb-0">
                    {t("Je n'ai pas de compte?")}
                    <Link to="price" data-toggle="tooltip" data-placement="top" className="text-primary ml-1">
                      {t("S'inscrire")}
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{t("Vérification du code")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">{t("Email avec un code de vérification est envoyé")}.</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter code"
              value={code}
              onChange={(e) => setcode(e.target.value)}
              onKeyPress={handleSubmitData}
            />
            <small id="emailHelp" className="form-text text-muted">
              {t("Ne partagez jamais votre code avec quelqu'un d'autre")}.
            </small>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Fermer")}
          </Button>

          {loadingSubmit ? (
            <button className="btn btn-info btn-loading btn-block">{t("Enregistrer")}</button>
          ) : (
            <Button variant="btn btn-info btn-block" onClick={handleSubmitDataAfterCode}>
              {t("Envoyer")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SignIn;

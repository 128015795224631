import React, { useEffect, useState } from 'react'

import Pagination from '../../Others/Pagination';
import ExportCsv from '../../Exports/ExportCsv';
import { useTranslation } from 'react-i18next';
import { Link } from '@react-pdf/renderer';
import CopieContent from '../../Exports/CopieContent';
import ErrorCustum from '../../Others/ErrorCustum';
import CustomSyncLoader from '../../Others/CustomSyncLoader';
import { useHistory } from "react-router-dom";
import ButtonHeaderComponent from '../../Styles/ButtonHeaderComponent';
import { toast } from 'react-toastify';
import { getjour_ferire } from '../../../Services/Pointeuse/EntrepriseApi';
import AjouterJourFerie from './AjouterJourFerie';
export default function ListJourFerie({refresh}) {


    const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
    let history = useHistory();
    const [currentData, setcurrentData] = useState([]);
    const [allInitialData, setallInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setdata] = useState(null);

    const [size, setsize] = useState(40);
    const [isCollapsed, setisCollapsed] = useState(false);

    const [dataobj, setdataobj] = useState()



    const handleChangeSize = (e) => {
        setsize(e.target.value);
        getData();
    };

    useEffect(() => {
        getData();
    }, [refresh]);

    const getData = () => {
        setLoading(true);
        getjour_ferire().then((res) => {
            setallInitialData(res?.data?.data);
            setdata(res?.data?.data);
            setLoading(false)
        });

    };

    const onChangePage = (pageOfItems) => {
        // update state with new page of items
        setcurrentData(pageOfItems);
    };
    const handleSearch = () => { }






    return (
        <div>
            <>

                <div className="page-header d-xl-flex d-block">
                    <div className="page-leftheader">
                        <h4 className="page-title">{t(" Jours fériés ")}</h4>
                    </div>
                    <div className="page-rightheader ml-md-auto">
                        <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                            <div className="btn-list">

                                <ButtonHeaderComponent />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-md-12 col-lg-12">
                        <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
                            <div className="card-header  border-0">
                                <h4 className="card-title">{t("List  des Jours fériés  ")}</h4>


                            </div>

                            <div className="card-body row">


                                {loading && <CustomSyncLoader></CustomSyncLoader>}
                                {!loading && error && <ErrorCustum></ErrorCustum>}
                                {!loading && !error && data && (
                                    <div className="table-responsive col-7">
                                        <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="hr-table_length">
                                                        <label>
                                                            {t("Afficher")}{" "}
                                                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                                                <option value={8}>8</option>
                                                                <option value={20}>20</option>
                                                                <option value={40}>40</option>
                                                                <option value={60}>60</option>
                                                                <option value={100}>100</option>
                                                            </select>{" "}
                                                            {t("entrées")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div id="hr-table_filter" className="dataTables_filter">
                                                        <label className="mr-2">
                                                            <input
                                                                type="search"
                                                                className="form-control"
                                                                placeholder={t("Recherche...")}
                                                                aria-controls="hr-table"
                                                                onChange={handleSearch}
                                                            />
                                                        </label>

                                                        <ExportCsv
                                                            data={data}
                                                            name={"List_exercices"}
                                                            columns={[
                                                                'id',
                                                                "libelle",
                                                                "date",
                                                                "nbre_jours",
                                                                "CP",


                                                            ]}
                                                        />
                                                        <CopieContent />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row c">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-vcenter text-nowrap table-bordered border-bottom  no-footer"
                                                        id="hr-table"
                                                        role="grid"
                                                        aria-describedby="hr-table_info"
                                                    >
                                                        <thead>
                                                            <tr >
                                                                <th className="border-bottom-0" >{t("Libelle")}</th>

                                                                <th className="border-bottom-0">{t("Date")}</th>
                                                                <th className="border-bottom-0">{t("Nombre de jour")}</th>

                                                                <th className="border-bottom-0">{t("Type")}</th>




                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {currentData.map((el) => (

                                                                <tr key={el.id}>

                                                                    <td onClick={() => setdataobj(el)}>{el.libelle}{' '}</td>
                                                                    <td>{el.date}</td>
                                                                    <td>   {el.nbre_jours}
                                                                    </td>
                                                                    <td>   {el.type}
                                                                    </td>


                                                                    <td >
                                                                        <Link to="#" onClick={() => setdataobj(el)} className="dropdown-item">
                                                                            {t("Editer")}
                                                                        </Link>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <th className="border-bottom-0" >{t("Libelle")}</th>

                                                                <th className="border-bottom-0">{t("Date")}</th>
                                                                <th className="border-bottom-0">{t("Nombre de jour")}</th>

                                                                <th className="border-bottom-0">{t("Type")}</th>


                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                                                        {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}

                                <div className='col-5'>
                                    <AjouterJourFerie objdata={dataobj} getData={getData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}

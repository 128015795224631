import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { PDFReader } from "reactjs-pdf-reader";
import swal from "sweetalert";
import { acceptConge, deleteDechargeById, editCongeType, senFcmConge, sentCongeEmail, updateDechargeById } from "../../Services/Pointeuse/CongeApi";
import { getEntrepriseByCode } from "../../Services/Pointeuse/EntrepriseApi";
import { addNumberToDate } from "../../Utils/DateUtils";
import ImageComponent from "../Styles/ImageComponent";
import firebase from "../../firebase";
import ShowDecharge from "./ShowDecharge";
import { useTranslation } from 'react-i18next'; // Importez le hook useTranslation

function TableConges({ onUpdate, arrayObject, status, onRemoveobject }) {
  const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
  const history = useHistory();
  const [logo, setlogo] = useState(null);
  const [motif, setmotif] = useState(null);
  const [userId, setuserId] = useState(null);
  const myRefDelete = useRef(null);
  const myRefAdd = useRef(null);
  const [isOpened, setIsOpened] = useState(false);
  const [fileUrl, setfileUrl] = useState(null);
  const [idConge, setidConge] = useState(null);
  const [data, setdata] = useState(arrayObject);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setimageName] = useState("Choisir un document ...");

  useEffect(() => {
    setdata(arrayObject);
  }, [arrayObject]);

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setlogo(res.data.logo);
    });
  }, []);

  /**
   * Accept a leave request
   * @param receiver - The email address of the user who is receiving the conge.
   * @param nom - The name of the user who sent the request.
   * @param prenom - The first name of the user who sent the request.
   * @param nbjour - Number of days of the leave
   * @param motif - The reason for the leave
   * @param id - The id of the conge you want to accept.
   * @param type - The type of the conge.
   * @param date - The date of the conge.
   * @param status - The status of the leave request.
   * @param userid - The id of the user who sent the request.
   */
  const handleAccept = (receiver, nom, prenom, nbjour, motif,affect_solde, id, type, date, status, userid) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment accepté congé!"),
      icon: "warning",
      // buttons: true,
      buttons: {
      cancel: t("Cancel"), // Traduisez le bouton "Cancel"
      confirm: {
        text: t("OK"), // Traduisez le bouton "OK"
        value: true,
      },
    },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
     
        acceptConge(id,affect_solde,userid, nbjour, "1").then(() => {
          //toast.success("Congé accepté");
          sentCongeEmail(receiver, nom, prenom, nbjour, motif, logo, id, type, date, status).then(() => {
            swal(t("Opération effectuée avec succès!"), {
              icon: "success",
            });
            const updatedData = arrayObject.filter((item) => item.id != id);
            setdata(updatedData);
            onRemoveobject(id);

            sendFirebaseNotification(status, userid);
          });
        });
      }
    });
  };

  /**
   * It sends a notification to the user.
   * @param type - The type of notification to send.
   * @param receiver - The user's token.
   */
  const sendFirebaseNotification = (type, receiver) => {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        return messaging.getToken();
      })
      .then(() => {
        senFcmConge(type, receiver);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * It sends a notification to the user who requested the leave.
   * @param id - The id of the leave request.
   * @param nbjour - number of days of the leave
   * @param receiver - The user who will receive the notification.
   */
  const handleReject = (id, nbjour, receiver) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez vous vraiment refusé congé!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        acceptConge(id, nbjour, "0").then(() => {
          //toast.success("Congé accepté");
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          const updatedData = arrayObject.filter((item) => item.id != id);
          setdata(updatedData);
          sendFirebaseNotification(t("refusé"), receiver);
        });
      }
    });
  };

  /**
   * It sets the motif to the type of motif and sets the userId to the id of the user.
   * @param type - The type of motif to be displayed.
   * @param id - The id of the user you want to get the motifs for.
   */
  const handleMotif = (type, id) => {
    setmotif(type);
    setuserId(id);
  };

  /**
   * It calls the API to save the new motif.
   */
  const handleSaveMotif = () => {
    editCongeType(motif, userId).then(() => {
      myRefDelete.current.click();
      onUpdate();
    });
  };

  /**
   * It navigates to the congeprint page with the id of the conge as a parameter.
   * @param id - The id of the conge to print.
   * @param obj - The object that you want to pass to the next page.
   */
  const navigateToDecharge = (id, obj) => {
    //history.push("/admin/congeprint/" + id, { state: obj });
    history.push({ pathname: "/admin/congeprint/" + id, obj });
  };

  /**
   * It deletes a decharge from the database.
   * @param id - The id of the record to delete.
   */
  const handleDeleteDecharge = (id) => {
    handleDismiss();
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Une fois supprimé, vous ne pourrez pas récupérer cet enregistrement!"),
      icon: "warning",
      // buttons: true,
      buttons: {
        cancel: t("Cancel"), // Traduisez le bouton "Cancel"
        confirm: {
          text: t("OK"), // Traduisez le bouton "OK"
          value: true,
        },
      },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteDechargeById(id).then(() => {
          swal(t("Opération effectuée avec succès!"), {
            icon: "success",
          });
          onUpdate();
        });
      }
    });
  };

  /**
   * * It opens a modal window with the file url
   * @param url - The url of the file to be opened.
   * @param id - The id of the conge to be opened.
   */
  const handleOpenModal = (url, id) => {
    setidConge(id);
    setIsOpened(true);
    setfileUrl(url);
    $(".sidebar-modal").addClass("active");
    $("body").addClass("overlay-open");
    $("body").append('<div class="overlay"></div>');
  };

  /**
   * * When the user clicks on the "X" button, the modal is closed and the body is no longer in the
   * "overlay-open" state
   */
  const handleDismiss = () => {
    $(".sidebar-modal").removeClass("active");
    $("body").removeClass("overlay-open");
    // $("body").append('<div class="overlay"></div>');
    setIsOpened(false);
    setfileUrl();
  };

  /**
   * It sets the imageUrl to the URL of the image file that is uploaded.
   * @param event - The event that triggered the function.
   */
  const handleUploadFiles = (event) => {
    setFilesToUpload(URL.createObjectURL(event.target.files[0]));
    setImageUrl(event.target.files[0]);
    setimageName(event.target.files[0].name);
  };

  /**
   * Update the decharge by id and the image url
   */
  const handleSubmitDecharge = () => {
    updateDechargeById(idConge, imageUrl).then((res) => {
      myRefAdd.current.click();
      onUpdate();
    });
  };
  return (
    <>
      <table className="table table-vcenter text-nowrap table-bordered border-bottom dataTable no-footer">
        <thead>
          <tr>
            <th className="border-bottom-0 w-5"></th>
            {/* <th>Collaborateur</th> */}
            <th className="border-bottom-0 w-5">{t("motif congé")}</th>
            <th className="border-bottom-0 w-5">{t("De")}</th>
            <th className="border-bottom-0 w-5">{t("à")}</th>
            <th className="border-bottom-0 w-4">{t("Nb-J")}</th>
            {/* <th className="border-bottom-0 w-5">Email</th> */}
            <th className="border-bottom-0 w-5">{t("Statut")}</th>
            <th className="border-bottom-0 w-5">{t("Titre congé")}</th>
            <th className="border-bottom-0 w-5">{t('Solde conge')}</th>
            <th className="border-bottom-0 w-5"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <tr key={el.id}>
              <td>
                <div className="d-flex">
                  <ImageComponent picture={el.imguser} atr={"avatar avatar-md brround mr-3"}></ImageComponent>
                  <div className="mr-8 mt-0 mt-sm-1 d-block">
                    <h6 className="mb-1 fs-14">
                      {el.nom} {el.prenom}
                    </h6>
                    <p className="text-muted mb-0 fs-12">{el.email}</p>
                  </div>
                </div>
              </td>
              {/* <td>
                <Link to ="#">
                  {el.nom} <span>{el.prenom}</span>
                </Link>
              </td> */}
              <td data-toggle="modal" data-target="#modalMotif" onClick={() => handleMotif(el.motif, el.id)} style={{cursor:"pointer"}}>
              {t(el.motif)}<i className="feather feather-edit text-info"></i>
              </td>

              <td>{el.date.split(" ")[0]}</td>
              <td>{addNumberToDate(el.nbr_jour, el.date)}</td>
              <td>{el.nbr_jour}</td>
              {/* {status} */}
              <td className="text-center">
                {status == "refusé" ? (
                  <span className="badge badge-danger">{t("refusé")}</span>
                ) : status == "demande" ? (
                  <span className="badge badge-warning">{t("demande")}</span>
                ) : (
                  <span className="badge badge-success">{t("accepté")}</span>
                )}
              </td>

              {/* file */}
              <td className="text-center">
                {el.type == "conge" && el.valide == "1" && (
                  <Link to="#" data-toggle="tooltip" data-placement="top" title="titre congé" onClick={() => navigateToDecharge(el.id, el)}>
                    <i className="feather feather-file text-info"></i>
                  </Link>
                )}
              </td>
              {/* action */}
              <td className="text-center">{el.soldeconge}</td>
              <td className="text-left">
                {status == "demande" && (
                  <>
                    <Link
                      to="#"
                      onClick={() =>
                        handleAccept(el.email, el.nom, el.prenom, el.nbr_jour, el.motif,el.affect_solde, el.id, el.type, el.date, "accepté", el.user_code_id)
                      }
                      className="action-btns"
                      data-tip="editer"
                    >
                      {/* <i className="feather feather-edit text-info" /> */}
                      <i className="fa fa-check-circle text-success"></i>
                    </Link>
                    <Link to="#" onClick={() => handleReject(el.id, el.nbr_jour, el.user_code_id)} className="action-btns" data-tip="editer">
                      {/* <i className="feather feather-edit text-info" /> */}
                      <i className="fa fa-ban text-danger"></i>
                    </Link>
                  </>
                )}
                {el.type == "conge" && el.valide == "1" && el.decharge && (
                  <>
                    <Link to="#" data-toggle="tooltip" data-placement="top" title="voir décharge" onClick={() => handleOpenModal(el.decharge, el.id)}>
                      <i className="feather feather-eye text-warning mr-2"></i>
                    </Link>
                    <Link to="#" data-toggle="tooltip" data-placement="top" title="supprimer décharge" onClick={() => handleDeleteDecharge(el.id)}>
                      <i className="feather feather-trash text-danger"></i>
                    </Link>
                  </>
                )}
                {el.type == "conge" && el.valide == "1" && !el.decharge && (
                  <Link
                    to="#"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="ajouter décharge"
                    //data-toggle="modal"
                    data-target="#adddecharge"
                    onClick={() => setidConge(el.id)}
                  >
                    <i className="feather feather-plus text-success"></i>
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Modal */}
      <div className="modal fade" id="modalMotif" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Type congé")}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setmotif(e.target.value)} value={motif}>
                <option value="CONGÉ PAYÉ">{t("CONGÉ PAYÉ")}</option>
                <option value="CONGÉ POUR RAISON FAMILIALE">{t("CONGÉ POUR RAISON FAMILIALE")}</option>
                <option value="CONGÉ MALADIE">{t("CONGÉ MALADIE")}</option>
                <option value="CONGÉ ANNUEL">{t("CONGÉ ANNUEL")}</option>
                <option value="CONGÉ D'EXAMEN">{t("CONGÉ D'EXAMEN")}</option>
                <option value="CONGÉ SANS SOLDE">{t("CONGÉ SANS SOLDE")}</option>
                <option value="CONGÉ INDIVIDUEL DE FORMATION">{t("CONGÉ INDIVIDUEL DE FORMATION")}</option>
                <option value="CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE">{t("CONGÉ FORMATION ÉCONOMIQUE, SOCIALE ET SYNDICALE")}</option>
                <option value="CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE">{t("CONGÉ D’ENSEIGNEMENT ET DE RECHERCHE")}</option>
                <option value="CONGÉ MATERNITÉ">{t("CONGÉ MATERNITÉ")}</option>
                <option value="CONGÉ PATERNITÉ">{t("CONGÉ PATERNITÉ")}</option>
                <option value="CONGÉ POUR CATASTROPHE NATURELLE">{t("CONGÉ POUR CATASTROPHE NATURELLE")}</option>
                <option value="CONGÉ SABBATIQUE">{t("CONGÉ SABBATIQUE")}</option>
                <option value="AUTRE">{t("AUTRE")}</option>
              </select>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefDelete}>
                {t("Annuler")}
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSaveMotif}>
                {t("Modifier")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* open Modal décharge */}

      <div className="task-fade sidebar-modal">
        <div className="sidebar-dialog task-modal">
          <div className="sidebar-content">
            <div className="sidebar-header">
              <div>
                <h4 className="mb-0 mt-4">{t("Décharge congé")} # {idConge}</h4>
              </div>
              <div className="ml-auto mt-3">
                <Link to="#" className="btn btn-danger mr-2" onClick={() => handleDeleteDecharge(idConge)}>
                  {("Supprimer décharge")}
                </Link>
                <Link to="#" className="action-btns" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="feather feather-more-horizontal" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-right" role="menu">
                  <li>
                    <Link to="#">
                      <i className="feather feather-eye mr-2" />
                      {t("Voir les décharge")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-plus-circle mr-2" />
                      {t("Ajouter décharge")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#">
                      <i className="feather feather-send mr-2" />
                      Send
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="feather feather-edit-2 mr-2" />
                      Edit
                    </Link>
                  </li> */}
                  <li>
                    <Link to="#" onClick={() => handleDeleteDecharge(idConge)}>
                      <i className="feather feather-trash-2 mr-2" />
                      {t("Supprimer")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#">
                      <i className="feather feather-settings mr-2" />
                      More
                    </Link>
                  </li> */}
                </ul>
                <Link to="#" className="action-btns dismiss" onClick={handleDismiss}>
                  <i className="feather feather-x" />
                </Link>
              </div>
            </div>
            <div className="row no-gutters border-bottom">{fileUrl && <ShowDecharge fileUrl={fileUrl}></ShowDecharge>}</div>
          </div>
        </div>
      </div>
      {/* Modal add decharge */}
      <div className="modal fade" id="adddecharge" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {t("Ajouter décharge")} # {idConge}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group file-browser">
                <input type="text" className="form-control browse-file" placeholder={imageName || "choisir un fichier ..."} />
                <label className="input-group-append">
                  <span className="btn btn-primary">
                    {t("choisir fichier")} <input type="file" style={{ display: "none" }} onChange={handleUploadFiles} />
                  </span>
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefAdd}>
                {t("Fermer")}
              </button>
              <button type="button" className="btn btn-primary" onClick={handleSubmitDecharge}>
                {t("Ajouter")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TableConges;

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "./../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/***   paie */
/**add */
export async function addPaie(data) {

  try {
    const response = await axios.post(api_url + "paie/", data, config);
    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**get */
export async function getPaie(mois, lastmois, year) {

  try {
    const response = await axios.get(api_url + "paie/getpaie/" + localStorage.getItem('code_generated') + '/' + mois + '/' + lastmois + '/' + year, config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**get */
export async function getinfovirement(mois, year) {

  try {
    const response = await axios.get(api_url + "paie/getinfovirement/" + localStorage.getItem('code_generated') + '/' + mois + '/' + year, config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**get paie year */
export async function getpaie_year( year,iduser) {

  try {
    const response = await axios.get(api_url + "paie/getpaie_year/" + localStorage.getItem('code_generated') + '/' + year+'/'+iduser, config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}
/**get societe */
export async function getlesmoisdupaie() {

  try {
    const response = await axios.get(api_url + "paie/getlesmoisdupaie/" + localStorage.getItem('code_generated'), config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**update paie */
export async function dupliquerpaie(data) {
 
  try {
    const response = await axios.post(api_url + "paie/dupliquerpaie" ,data, config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/**cloture all */
export async function cloturerpaie(data) {

  try {
    const response = await axios.put(api_url + "paie/cloturerpaie/", data, config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/*********cloturer une  */
export async function cloturerpaie_id(data) {

  try {
    const response = await axios.put(api_url + "paie/cloturerpaie/userid", data, config);

    //toast.success("ajouté");
    return response;
  } catch (error) {

    console.log(error.config);
    return error;
  }
}

/***delete ligne */
export async function deletelignepaie(code){
  try {

    const response = await axios.delete(api_url + "paie/deleteligne/"+code, config);

    return response;
  } catch (error) {

    return error;
  }
}


import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { cloturerpaie, dupliquerpaie, getPaie, getinfovirement, getlesmoisdupaie } from '../../../Services/paie/paie';
import { Accordion } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import { getEntrepriseByCode, getExercice } from '../../../Services/Pointeuse/EntrepriseApi';
import { getuser_personnel, getuser_personnel_actif, getUsersByCode } from '../../../Services/Pointeuse/UsersApi';
import ImageComponent from '../../Styles/ImageComponent';
import { t } from "i18next";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import { filterUserByWord } from '../../../Utils/SortObject';
import { FaCopy } from "react-icons/fa";
import OrdreVirement from '../files/OrdreVirement';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { IoIosCloseCircle } from "react-icons/io";

import { PDFViewer } from '@react-pdf/renderer';
import { formatFloat } from '../../../Utils/helper';
import Journalpaie from '../files/Journalpaie';
export default function AccueilPaie() {
  const history = useHistory();
  const [groupedData, setGroupedData] = useState([]);
  const listmois = ['Janvier', 'fevrier', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'decembre'];
  const [exercice, setExercice] = useState([]);
  const [exercice_selectionne, setexercice_selectionne] = useState();
  const [ajout, setajout] = useState(false);
  const [data, setdata] = useState([]);
  const [listuser, setlistuser] = useState([]);

  const [user_selected, setuser_selected] = useState([])
  const [mois_selected, setmois_selected] = useState('Janvier')
  const [dateP, setdateP] = useState()
  const [societe, setsociete] = useState()
  const [mois_virement, setmois_virement] = useState()


  const [list_virement, setlist_virement] = useState([])
  const [virement, setvirement] = useState()
  const [listjournal, setlistjournal] = useState([])
  const [Totaljournal, setTotaljournal] = useState({})

  const [JP, setJP] = useState(false)

  useEffect(() => {
    getEntrepriseByCode().then((res) => {
      setsociete(res.data)
    })

  }, [])

  useEffect(() => {
    const fetchLesMoisDuPaie = async () => {
      try {
        const res = await getlesmoisdupaie();
        const data = res.data;
        const groupedExercice = _.groupBy(data, 'exercice');

        setGroupedData(groupedExercice);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLesMoisDuPaie();
  }, []);
  useEffect(() => {
    if (groupedData && exercice_selectionne) {
      const last_element = groupedData[exercice_selectionne]?.slice(-1)[0];
      const month_index = listmois?.findIndex(item => item === last_element?.mois);
      setmois_selected(listmois[month_index + 1])
      setmois_virement(listmois[month_index])
    }


  }, [groupedData, exercice_selectionne])
  useEffect(() => {
    getExercice().then((res) => {
      if (Array.isArray(res?.data)) {
        setexercice_selectionne(res?.data[0]?.id)
        setExercice(res.data);
        const date = res.data[0].code_exercice + '-' + '01'
        setdateP(date)
      }

    });
  }, []);

  const get_Paie = (month) => {
    let mois = listmois.findIndex(item => item === month) + 1;
    mois = mois < 10 ? `0${mois}` : mois;
    const select_exercice = exercice.find(item => item.id === exercice_selectionne);

    const date = `${select_exercice?.code_exercice}-${mois}`;

    history.push({ pathname: `/admin/MatricePaie/`, state: { date: date, exercice: exercice_selectionne } });
  };
  const handleChangeDate = (month) => {
    setmois_selected(month)
    let mois = listmois.findIndex(item => item === month) + 1;

    mois = mois < 10 ? `0${mois}` : mois;
    const select_exercice = exercice.find(item => item.id === exercice_selectionne);

    const date = `${select_exercice?.code_exercice}-${mois}`;
    setdateP(date)
  };

  const handleChangeexercice = (value) => {
    setexercice_selectionne(parseInt(value))
    let mois = dateP.split('-')[1]

    const select_exercice = exercice.find(item => item.id === parseInt(value));

    const date = `${select_exercice?.code_exercice}-${mois}`;

    setdateP(date)
  };
  const getData = () => {
    if (groupedData[exercice_selectionne] && groupedData[exercice_selectionne][groupedData[exercice_selectionne].length - 1].c === 0) {
      toast.warning(t("le dernier mois n'est pas clôturé"))
    } else {
      getUsersByCode()
        .then((res) => {
          setdata(res.data);
          setlistuser(res.data)
          setajout(true)
          const select_exercice = exercice.find(item => item.id === exercice_selectionne);
          const findindex = listmois.findIndex(item => item === mois_selected)
          const i = findindex + 1
          const month = i < 10 ? '0' + i : i
          const date = `${select_exercice?.code_exercice}-${month}`;

          setdateP(date)

        })
    }

  };
  const selectionall = () => {

    const alldata = data.map(item => item.id)
    setlistuser(data)
    setuser_selected(alldata)
  }


  const select_user = (userid) => {
    const findindexuser = user_selected.findIndex(item => item === userid);
    if (findindexuser !== -1) {
      const list = [...user_selected];
      list.splice(findindexuser, 1);
      setuser_selected(list);
    } else {

      setuser_selected((prev) => [...prev, userid])

    }
  }

  const cloturer = (valcloture, mois) => {
    swal({
      title: t("Ëtes-vous sûr ?"),
      text: t("Voulez-vous cloturer le paie "),
      icon: "warning",
      buttons: {
        cancel: t("Annuler"),
        confirm: {
          text: t("OK"),
          value: true,
        },
      },
      dangerMode: true,
    }).then((res) => {
      if (res) {
        const year = dateP.split('-')[0];

        const data_cloture = { mois: mois, year: year, cloture: valcloture, code_generated: localStorage.getItem('code_generated') }
        cloturerpaie(data_cloture).then((rescloturer) => {
          if (rescloturer.status === 200) {

            toast.success(t("Le paiement a été  clôturé"))
            window.location.reload()


          } else {
            toast.error(t('Un problème était produit'))

          }


        })
      }
    });
  }

  const handleSearch = (e) => {
    const dataSearch = filterUserByWord(data, e.target.value);
    setlistuser(dataSearch);
  };
  const dupliquer_paie = async (item) => {
    const index_mois = listmois.findIndex(e => e === item.mois);

    const last_mois = listmois[index_mois]


    if (item.c === item.count) {
      const data = { mois: item.mois, annee: item.annee, nouvelle_mois: mois_selected, last_mois: last_mois, code_generated: localStorage.getItem('code_generated') }
      const res = await dupliquerpaie(data)
      if (res.status === 200) {
        window.location.reload()
      }
    } else {
      toast.warning("le paie n'est ne pas clôturer")
    }

  }

  const getOrder_virement = (item) => {
    setJP(false)
    setvirement(true)
    getinfovirement(item.mois, item.annee).then((res) => {
      setlist_virement(res.data)

    })
  }


  const journal_paie = (item) => {
    if (dateP) {

      let users
      getuser_personnel(dateP).then((res) => {

        users = res.data
        getPaie(item.mois, null, item.annee).then((res) => {

          if (res.status === 200 && res.data?.data?.success !== 0) {

            let nouvelleListe = [];
            res.data.listpaie.forEach(paiement => {
              let utilisateur = users?.find(user => user.code === paiement.id_users);

              if (utilisateur) {
                const copie_paie = { ...paiement }
                copie_paie.nom = utilisateur.nom
                copie_paie.prenom = utilisateur.prenom
                copie_paie.matricule_user = utilisateur.matricule_user
                copie_paie.salbrut = formatFloat(paiement.salaire_brut)
                copie_paie.cnss = formatFloat(paiement.retenue_cnss)
                copie_paie.imposable = formatFloat(paiement.salaire_imposable)
                copie_paie.irpp = formatFloat(paiement.calcul_irpp_mois)
                copie_paie.css = formatFloat(paiement.calcul_irpp_css_mois - paiement.calcul_irpp_mois)

                copie_paie.netapayer = formatFloat(paiement.net_servi_avant_arrondi)


                const rt = paiement.paie_ligne?.reduce((acc, curr) => {
                  if (curr.champs === "retenue") {

                    if (curr.presence === 1) {
                      if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                        return acc + ((curr.valeur_annuel / paiement.nb_s) * paiement.total);
                      } else {
                        return acc + (curr.valeur_mensuel * paiement.total);
                      }

                    } else {
                      if (curr.valeur_annuel && curr.valeur_annuel !== 0) {
                        return acc + ((curr.valeur_annuel / paiement.nb_s));
                      } else {
                        return acc + parseFloat(curr.valeur_mensuel);
                      }
                    };
                  }
                  return acc; // Ensure that we return the accumulator even if the condition is not met
                }, 0);
                const obj = {
                  mois: item.mois,
                  code: utilisateur.code,
                  nom: utilisateur.nom,
                  prenom: utilisateur.prenom,
                  matricule_user: utilisateur.matricule_user,
                  salbrut: formatFloat(paiement.salaire_brut) || 0,
                  cnss: formatFloat(paiement.retenue_cnss) || 0,
                  imposable: formatFloat(paiement.salaire_imposable) || 0,
                  irpp: formatFloat(paiement.calcul_irpp_mois) || 0,
                  css: formatFloat(paiement.calcul_irpp_css_mois - paiement.calcul_irpp_mois) || 0,

                  netapayer: formatFloat(paiement.net_servi_avant_arrondi) || 0,
                  netapayerarrondi: formatFloat(paiement.net_a_payer) || 0,
                  totalretenu: rt

                }
                const findindex = nouvelleListe.findIndex(u => u.code === obj.code)
                if (findindex === -1) {
                  nouvelleListe.push(obj);

                }
              }
            });
            console.log("nouvelleListe",nouvelleListe)
            setlistjournal(nouvelleListe)
            const total = {
              mois: nouvelleListe[0].mois,
              code: "",
              nom: "",
              prenom: "",
              matricule_user: "",
              salbrut: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.salbrut), 0)) || 0,
              cnss: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.cnss), 0)) || 0,
              imposable: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.imposable), 0)) || 0,
              irpp: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur +parseFloat(item.irpp) , 0)) || 0,
              css: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.css), 0)) || 0,

              netapayer: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.netapayer), 0)) || 0,
              netapayerarrondi: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.netapayerarrondi), 0)) || 0,
              totalretenu: formatFloat(nouvelleListe.reduce((accumulateur, item) => accumulateur + parseFloat(item.totalretenu), 0)) || 0
            }

            setTotaljournal(total)
            setvirement(false)
            setJP(true)


          } else {
            toast.error(res.data?.data.message)
          }
        })
      })
      downloadPDF()
    }



  }

  const downloadPDF = () => {
    // Find the active tab pane
    const activeTabPane = document.getElementById('journalpaie');

    if (activeTabPane) {
      // Use html2canvas to capture the content of the active tab pane
      html2canvas(activeTabPane)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF();
          const imgWidth = 210; // A4 size width in mm
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          pdf.save('fiche_paie_.pdf');
        });
    } else {
      console.error('No active tab pane found.');
    }
  };



  return (
    <div>
      <h1>{t('Liste de paies')}</h1>
      <div className="card">
        <div className="card-body">
          <div className="col-3 ">
            <select className="form-control" onChange={(e) => handleChangeexercice(e.target.value)}>
              {exercice.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.code_exercice}
                </option>

              ))}
            </select>

          </div>




          <table className="mt-5 mb-5 table  table-vcenter text-nowrap table-bordered border-top border-bottom" id="hr-attendance">

            <thead>
              <th>{t('Mois')}</th>
              <th>{t('Somme net a payer')}</th>

              <th>{t('Etat')}</th>
              <th>{t('Order de Virement ')}</th>
              <th>{t('Journal de paie ')}</th>
              <th></th>


            </thead>
            <tbody>
              {groupedData[exercice_selectionne] && groupedData[exercice_selectionne].length !== 0 && (groupedData[exercice_selectionne]?.map((item, index) => (
                <tr>
                  <td onClick={() => get_Paie(item?.mois)}>{item?.mois}</td>
                  <td>{item?.sum_net?.toFixed(3)}</td>

                  <td>{item?.c === 0 ? <strong style={{ color: 'red' }} onClick={() => cloturer(1, item?.mois)}>{t('non cloturer')}</strong> : <strong style={{ color: 'green' }} onClick={() => cloturer(0, item?.mois)}>{item?.c < item?.count ? 'partiellement cloturé' : 'cloturé'}</strong>} </td>
                  {item?.c === item?.count ? <td className='lien' onClick={() => getOrder_virement(item)}>Order de virement</td> : <td></td>}
                  {item?.c === item?.count ? <td className='lien' onClick={() => journal_paie(item)}>Journal de paie</td> : <td></td>}

                  {index !== groupedData[exercice_selectionne].length && <p onClick={() => dupliquer_paie(item)} style={{ fontSize: '15px' }}><FaCopy /></p>}
                </tr>


              )))}
            </tbody>
          </table>
          <button
            className="btn btn-primary float-right"
            onClick={() => {
              ajout ? setajout(false) : getData();
            }}
          >

            <i className="far fa-credit-card" /> {ajout ? 'fermer' : "Ajouter"}
          </button>
          <div>
            {ajout && (
              <>
                <div className='row'>
                  <select
                    className="form-control col-4"
                    value={mois_selected}
                    onChange={(e) => handleChangeDate(e.target.value)}
                  >
                    {(groupedData[exercice_selectionne] === undefined || groupedData[exercice_selectionne].length === 0
                      ? listmois
                      : listmois.filter(item => !groupedData[exercice_selectionne].map(element => element.mois).includes(item))
                    ).map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}

                  </select>
                  <div className='col-3'>
                    <input
                      type="search"
                      className="form-control"
                      placeholder={t("Recherche...")}
                      aria-controls="hr-table"
                      onChange={handleSearch} />
                  </div>
                  <div className='col-2'>
                    {user_selected.length === 0 ? (
                      <p className="pointer link" onClick={selectionall}>
                        {t('Sélection tout')}
                      </p>
                    ) : (
                      <p className="pointer link" onClick={() => setuser_selected([])}>
                        {t('Désélectionné')}
                      </p>
                    )}
                  </div>
                </div>




                {listuser.map((item) => (
                  <div className="d-flex" key={item.id}>
                    <input
                      type="checkbox"
                      onClick={() => select_user(item.id)}
                      checked={user_selected.includes(item.id)} />
                    <ImageComponent atr="avatar avatar-md brround mr-3" picture={item.img} />
                    <div className="mr-3 mt-0 mt-sm-2 d-block">
                      <h6 className="mb-1 fs-14">
                        {item.nom} {item.prenom}
                      </h6>
                    </div>
                  </div>
                ))}

                <Link
                  to={{
                    pathname: '/admin/MatricePaie/',
                    state: { user_selected: user_selected, date: dateP, exercice: exercice_selectionne }
                  }}

                  className="btn btn-primary float-right ml-2"
                >
                  {t('Calcul de paie')}
                </Link>
              </>
            )}
          </div>

        </div>
      </div>
      {virement && (
        <>
          <div onClick={() => setvirement(false)}><IoIosCloseCircle /></div>
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <OrdreVirement societe={societe} t={t} mois={mois_virement} year={exercice?.find(item => item.id === exercice_selectionne)?.code_exercice} list={list_virement} />
          </PDFViewer>
        </>
      )
      }
      {JP && (
        <PDFViewer style={{ width: "100%", height: "800px" }}>
          <Journalpaie list={listjournal} total={Totaljournal} societe={societe} mois={mois_virement} year={exercice?.find(item => item.id === exercice_selectionne)?.code_exercice} />
        </PDFViewer>
      )
      }


    </div>

  );
};

import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import { calculateHt, calculateRemise, calulateTva, MultiplicateNumber } from "../../../../../Utils/NumberUtils";
import { getGoupedValue } from "../../../../../Utils/ObjectUtils";
import {
  addEntete,
  addEnteteOther,
  deleteEnteteByCode,
  getEnteteByCode,
  getEnteteCommercialVenteAbonnement,
  getEnteteCommercialVenteBl,
} from "../../../../../Services/Facturation/FacturationEnteteApi";
import { getEnteteMediaFacture } from "../../../../../Services/Facturation/FacturationEnteteMediaApi";
import { addLigne, addLigneAther, getLigneByCode } from "../../../../../Services/Facturation/FacturationLigneApi";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";
import {
  getClientByCodeId,
  getClientById,
  getFacturationClientByCode,
} from "../../../../../Services/Facturation/FacturationClientApi";
import { getAllProduct, getProduitById } from "../../../../../Services/Facturation/FacturationProduitApi";
import { getEntrepriseByCode } from "../../../../../Services/Pointeuse/EntrepriseApi";
import MyDocument from "../../../invoiceReports/MyDocument";
import axios from "axios";
import { getToken, getSession } from "../../../../../Utils/SessionUtils";
import { t } from "i18next";
import { useHistory } from "react-router-dom";
import { getAbonnementAndEnteteByProduitFacture, getAllAbonnementByCode } from "../../../../../Services/Facturation/FacturationAbonnementApi";
import { ajoutAbonnement, getDetailsAbonnement, getLastIDAbonnement, updateAbonnement } from "../../../../../Services/abonnement/abonnement";
import { calculateEndDate, calculateMonthDifference } from "../../../../../Utils/DateUtils";
import { PDFViewer } from "@react-pdf/renderer";
import { handleKeyPress_number } from "../../../../../Utils/InputUtils";
import { checkEmptyPriceQuantity } from "../../../../../Utils/ObjectOperation";

function EditerAbonnement(props) {
  const history = useHistory();
  const api_url = process.env.REACT_APP_API_BASE_URL_V1;
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  //
  const [showResults, setShowResults] = React.useState(false);
  const [dataFournisseur, setDataFournisseur] = useState([]);
  const [fournisseurFullName, setFournisseurFullName] = useState("");
  const [sousTotalHt, setsousTotalht] = useState(0);
  const [actionRemiseEntete, setactionRemiseEntete] = useState("1");
  const [totalHt, setTotalHt] = useState("");
  const [listTva, setlistTva] = useState([]);
  const [totalTtc, setTotalTTC] = useState("");
  const [timbre_fiscale, setTimbreFiscal] = useState("");
  const [products, setProducts] = useState([]);
  const [libelleProduct, setLibelleProduct] = useState("");
  const [objectToPdf, setobjectToPdf] = useState();

  const [client, setclient] = useState()

  const [societe, setsociete] = useState()
  const [entet_data, set_entet_data] = useState({
    code_abonnement: "",
    code_commande: "",
    client_code: "",
    societe_code: localStorage.getItem('code_generated'),
    date_debut: "",
    date_fin: "",
    nb_mois: "",
    frequence_facturation: "",
    Delais_rappel: "",
    remise_pied: 0,
    total_brut_Ht: 0,
    montant_remise:0,
    totalHt:0,
    net_ht:0,
    montant_total_tva:0,
    totalTtc:0,
    timbre:0,
    net_a_payer:0,
    created_at: "",
    note: '',
    email:'',
    user_code_id: localStorage.getItem('id'),
   
  })

  const [inputList, setInputList] = useState([
    {
      id: '',
      produit_code: "",
      libelleProduit: "",
      quantite: 0,
      prixHt: 0,
      remise: 0,
      tvaProduct: 0,
      actionRemise: "1",
      totalHt: 0,
      nbr_users: 0,
      nbr_mois:0,
      dateDebut: '',
      dateFin: '',
      echeance: '',
      prix_achat_ht:0,
      prix_achat_ttc:0,
      num_serie:'',
      suivie_stock:0
      



    },
  ]);
useEffect(() => {
  getAllSociete().then((res) => {
    if (res.data) {
      setsociete(res.data);
    }
  });
}, []);

  useEffect(() => {
    getAllProduct().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.libelle,
        secondValue: d.id,
        thirdValue: d.prix_vente_ht_1,
        fourthValue: d.tva,
        suivieValue: d.suivie_stock,
        numSerie: d.num_serie,
        prix_achat_ht: d.prix_achat_ht,
        prix_achat_ttc: d.prix_achat_ttc , 
        
      }));
      setProducts(options);
    });
  }, [])
  useEffect(() => {
    getFacturationClientByCode().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
      }));
      setDataFournisseur(options);
    });
  }, [])

  useEffect(() => {
    if (props.match.params.id) {
      getDetailsAbonnement(props.match.params.id).then((res) => {
        // set entete
        const respdata = res.data?.data.entet[0];
        const listinputs = res.data?.data?.details
       
        const entet_data = {
          code_abonnement: respdata.code_abonnement,
          code_commande: respdata.code_commande,
          client_code: respdata.client_code,
          societe_code: respdata.societe_code,
          date_debut: respdata.date_debut,
          date_fin: respdata.date_fin,
          nb_mois: respdata.nb_mois,
          frequence_facturation: respdata.frequence_facturation,
          Delais_rappel: respdata.Delais_rappel,
          remise_pied: respdata.remise_pied,
          total_brut_Ht: respdata.total_brut_Ht,
          montant_remise:respdata.montant_remise,
          totalTtc:respdata.totalTtc,
          totalHt:respdata.totalHt,
          net_ht:respdata.net_ht,
          montant_total_tva:respdata.montant_total_tva,
          net_a_payer:respdata.net_a_payer,
          created_at: respdata.created_at,
          echeance: respdata.echeance,
          note: respdata.note,
          email: respdata.email ,
          timbre: respdata.timbre,
          user_code_id: respdata.user_code_id,
        }
        setFournisseurFullName(dataFournisseur.find(item => item.value === entet_data.client_code)?.label)

        set_entet_data(entet_data)
        //details 
       
        setInputList(listinputs)
      })
    } else {
      getLastIDAbonnement().then((res) => {
        if (res?.data?.data) {

          if (res.data.data.last_code) {

            const lastsouche = res.data.data.last_code.split(res.data.data.seq).pop();
            set_entet_data(prev => ({
              ...prev,
              code_abonnement: `${res.data.data.seq}${parseInt(lastsouche) + 1}`,

            }));
          }
          else {
            set_entet_data(prev => ({
              ...prev,
              code_abonnement: `${res.data.data.seq}1`

            }));
          }
        }
      });
    }

  }, [products, dataFournisseur])
  useEffect(() => {
 // calcul total ht brut sans remise
 const totalht_brut= inputList.reduce((accumulator, currentValue) => accumulator + (currentValue.prixHt * currentValue.quantite ), 0);
 // calcul montant remise
 let total_remise = 0;
 inputList.forEach(element => {
   if (element.actionRemise === '1') {
     const totalsansremise = element.prixHt * element.quantite;
     const montantremise = (totalsansremise * parseFloat(element.remise)) / 100;
     total_remise += montantremise;
     console.log('total_remise',total_remise)
   } else {
     total_remise += parseFloat(element.remise);
 
   }
 });
 // calcul total ht 
 const total_ht= inputList.reduce((accumulator, currentValue) => accumulator + currentValue.totalHt, 0);
 const total_ht_remise = total_ht*((100-entet_data.remise_pied)/100)
// montant tva 
const montant_tva = inputList.reduce((accumulator, currentValue) => accumulator + (currentValue.totalHt * parseFloat(currentValue.tvaProduct)), 0);
// const calcul ttc
const total_ttc = total_ht_remise + montant_tva ;

let total_a_payer = total_ttc
if(entet_data.timbre && entet_data.timbre !==0 ){
  total_a_payer  += entet_data.timbre  

}  else{
  total_a_payer  +=  societe?.timbre_fiscale

}

  set_entet_data(prev => ({
              ...prev,
              total_brut_Ht: parseFloat(totalht_brut.toFixed(3)) ||0,
              montant_remise:parseFloat(total_remise.toFixed(3))||0,
              totalHt:parseFloat(total_ht.toFixed(3))||0,
              net_ht:parseFloat(total_ht_remise.toFixed(3))||0,
              montant_total_tva:parseFloat(montant_tva.toFixed(3))||0,
              totalTtc:parseFloat(total_ttc.toFixed(3)) ||0,
              net_a_payer:parseFloat(total_a_payer.toFixed(3))||0
            }));

  }, [inputList ])


  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    // Mettre à jour les champs dans la liste
    if (name === 'prixHt' || name === 'remise') {

      list[index][name] = parseFloat(value);

    }
    if (name === 'dateDebut' || name === 'dateFin') {
      list[index][name] = moment(value).format("YYYY-MM-DD");
      if (name === 'dateFin') {
        //calcul nbr des mois 
        const nbrmois = calculateMonthDifference(list[index]?.dateDebut || entet_data.date_debut, value)
        list[index]['nbr_mois'] = nbrmois
      } else {
        const nbr_mois = calculateMonthDifference(value, list[index]?.dateFin)
        list[index]['nbr_mois'] = nbr_mois
        list[index]['quantite'] = nbr_mois* list[index].nbr_users
        list[index]['echeance'] = moment(value).format("YYYY-MM-DD");

        

      }

    }

    // Mettre à jour la quantité en fonction du nombre de user * nombre de mois
    if (name === 'nbr_mois' || name === 'nbr_users') {
      list[index][name] = parseInt(value);
      if (name === 'nbr_mois') {
        list[index]['quantite'] = list[index]['nbr_users'] * parseInt(value);;

      } else {
        list[index]['quantite'] = list[index]['nbr_mois'] * parseInt(value);;

      }

    }
    else {

      list[index][name] = value;

    }
    // Calculer le totalHt en utilisant les nouvelles valeurs
    let sum = calculateRemise(
      MultiplicateNumber(list[index]["prixHt"], list[index]["quantite"]),
      list[index]["remise"],
      list[index]["actionRemise"]
    );
    list[index]["totalHt"] = sum;
    // Mettre à jour l'état avec la nouvelle liste
    setInputList(list);
  
   };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);

    var sumTotal = 0;

    for (const [key, value] of Object.entries(list)) {
      sumTotal += value.totalHt;
    }
    var sumht
    if (sumTotal !== '0') {
      sumht = sumTotal?.toFixed(3);

    } else {
      sumht = sumTotal;

    }
    setsousTotalht(sumht);

    setTotalHt(calculateRemise(sumTotal, entet_data.remise, actionRemiseEntete).toFixed(3));

    let totalHtPrice = getGoupedValue(list, actionRemiseEntete, entet_data.remise, sumht);
    setlistTva(totalHtPrice);
    let sumTtc = 0;
    for (let i = 0; i < totalHtPrice.length; i++) {
      sumTtc += Number(totalHtPrice[i].split(" ")[3]);
      //console.log(calculateRemise(sum, remise, actionRemiseEntete))
    }
    let calculateTotalTtc = sumTtc + calculateRemise(sumTotal, entet_data.remise, actionRemiseEntete);
    var addTimbreFiscale = Number(calculateTotalTtc) + Number(1.0);
    setTotalTTC(addTimbreFiscale.toFixed(3));
  };

  // handle click event of the Add button
  // create new object
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        id: '',
        produit_code: "",
        libelleProduit: "",
        quantite: 0,
        prixHt:0,
        remise: 0,
        tvaProduct:0,
        actionRemise: "1",
        totalHt:0,
        suivie_stock: "",
        nbr_users: '',
        nbr_mois: calculateMonthDifference(entet_data.date_debut ,entet_data.date_fin),
        dateDebut: moment(entet_data.date_debut).format("YYYY-MM-DD"),
        dateFin: moment(entet_data.date_fin).format("YYYY-MM-DD"),
        echeance:  moment(entet_data.date_debut).format("YYYY-MM-DD"),
        prix_achat_ht:0,
        prix_achat_ttc:0,
        num_serie:'',
        suivie_stock:0
        
      },
    ]);
  };


  //select fournisseur
  const handleChangeFournisseur = (event) => {

    getClientById(event.secondValue).then((res) => {
      setFournisseurFullName(res.data.nom)
      setclient( res.data)
      set_entet_data(prev => ({
        ...prev,  //
        client_code: res.data.code,
        remise_pied : res.data.remise_client,
        totalHt:  parseFloat(calculateRemise(entet_data.sous_total_ht, entet_data.remise, actionRemiseEntete).toFixed(3)),
        email: res.data.email

      }));
    });
  };


  const handleSaveEntete = () => {

    /*let sumRemise = 0;
    for (const [key, value] of Object.entries(inputList)) {
      sumRemise += (Number(value.quantite) * Number(value.prixHt)) - Number(value.totalHt);

    }
    var remise_commande = Number(sousTotalHt) - Number(totalHt);
    var remise_total = Number(sumRemise) + remise_commande;
    var tva1 = 0;
    var tva2 = 0;
    var tva3 = 0;
    var tvaPourcent1 = 0;
    var tvaPourcent2 = 0;
    var tvaPourcent3 = 0;

    if (listTva.length === 1) {
      tva1 = listTva[0].split(" ")[3];
      tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
    } else if (listTva.length === 2) {
      tva1 = listTva[0].split(" ")[3];
      tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
      tva2 = listTva[1].split(" ")[3];
      tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
    } else if (listTva.length === 3) {
      tva1 = listTva[0].split(" ")[3];
      tvaPourcent1 = listTva[0].split(" ")[2].slice(0, -1);
      tva2 = listTva[1].split(" ")[3];
      tvaPourcent2 = listTva[1].split(" ")[2].slice(0, -1);
      tva3 = listTva[2].split(" ")[3];
      tvaPourcent3 = listTva[2].split(" ")[2].slice(0, -1);
    } else {
      console.log(t("empty listTva"));
    }
*/
    //  let montantTotalTva = Number(tva1) + Number(tva2) + Number(tva3);
    //  let codeEntete = parseInt(Date.now() * Math.random());
    // let net_payer = Number(totalTTC);

    /*if (en === "") {
      toast.error(t("Veuillez choisir un client"));
    } else if (!inputList[0].produit_code) {
      toast.error(t("Veuillez choisir au moins un produit"));
    } else if (!dateFinContrat) {
      toast.error(t("Veuillez choisir la date Fin de contrat"));
    } else if (!numFacture) {
      toast.error(t("Veuillez choisir numéro de facture"));
    } else if (!numCommande) {
      toast.error(t("Veuillez choisir numéro de commande"));
    } else if (checkEmptyPriceQuantity(inputList)) {

      toast.error(t("Veuillez ajouter quantité/prix"));
    } else {*/

    if(props.match.params.id){
      const entetdate = { ...entet_data }
      entetdate.updated_at = moment(new Date).format("YYYY-MM-DD");
      const details = inputList.map(item => {
        return {
          ...item,
          code_abonnement: entet_data.code_abonnement,
          created_at: item.id ? item.created_at : moment(new Date).format("YYYY-MM-DD"),
          updated_at: item.id ? moment(new Date).format("YYYY-MM-DD") : null,
          dateDebut: item.dateDebut === '' ? entet_data.date_debut : item.dateDebut,
        };
      });
  
  
      const data = { entet: entetdate, detail: details }
      updateAbonnement(data).then((res) => {
        if(res.status === 200){
          history.push("/admin/listabonnement");

        }
      })
  
  
    }else{
      if (entet_data.client_code === "") {
        toast.error(t("Veuillez choisir un client"));
      } else if (!inputList[0].produit_code) {
        toast.error(t("Veuillez choisir au moins un produit"));
      } else if (!entet_data.date_fin) {
        toast.error(t("Veuillez choisir la date Fin de contrat"));
      } 
       else if (!entet_data.code_commande) {
        toast.error(t("Veuillez choisir numéro de commande"));
      } else if (checkEmptyPriceQuantity(inputList)) {
  
        toast.error(t("Veuillez ajouter quantité/prix"));
      } else {
         const total_tva = inputList.reduce((accumulator, currentValue) => accumulator + currentValue.tvaProduct, 0);
        const details = inputList.map(item => {
          delete item.suivie_stock
          return {
            ...item,
            code_abonnement: entet_data.code_abonnement,
            created_at: entet_data.created_at,
            actionRemise :  item.actionRemise !== '1' ? ' montant' : 'pourcent',
            dateDebut: item.dateDebut === '' ? entet_data.date_debut : item.dateDebut,
          };
        });
        
       if(societe?.timbre_active === 1)  entet_data.timbre = societe.timbre_fiscale ;
       entet_data.montant_total_tva=total_tva

        const data = { entet: entet_data, detail: details }
        ajoutAbonnement(data).then((res) => {
          if(res.status === 200){
            history.push("/admin/listabonnement");

          }
        })
      }
  
    }
 

  };



  const handleShowFacture = () => {
    var remiseLigne = inputList
      .map((o) => o.remise)
      .reduce((a, c) => {
        return a + c;
      });
    var isRemise = false;
    if (remiseLigne > 0) {
      isRemise = true;
    }

    // console.log("remiseLigne");
    // console.log(isRemise);
    setobjectToPdf({

      code_abonnement: entet_data.code_abonnement,
      code_commande: entet_data.code_commande,
      client_name: fournisseurFullName,
      client_code: entet_data.client_code,
      societe_code: entet_data.societe_code,
      date_debut: entet_data.date_debut,
      date_fin: entet_data.date_fin,
      nb_mois: entet_data.nb_mois,
      frequence_facturation: entet_data.frequence_facturation,
      Delais_rappel: entet_data.Delais_rappel,
      remise: entet_data.remise,
      sous_total_ht: entet_data.sous_total_ht,
      totalHt: entet_data.totalHt,
      created_at: entet_data.created_at,
      remise: entet_data.remise,
      timbre: entet_data.timbre,

    })

    setShowResults(!showResults);
  };
  const handelchange_entet = (filed, value) => {
    set_entet_data(prev => ({
      ...prev,
      [filed]: value
    }));
    if (filed === 'date_debut') {
      let new_input_datefin ='';
      if (entet_data.nb_mois !== '') {
        const newDateFinContrat = calculateEndDate(value, entet_data.nb_mois);
        set_entet_data(prev => ({
          ...prev,
          date_fin: newDateFinContrat
        }));
        new_input_datefin=newDateFinContrat
 
      } 
      if (!props.match.params.id) {
        set_entet_data(prev => ({
          ...prev,
          echeance: value
        }));
      }
      const newlist = inputList.map(item => {
        return { ...item, 
          dateDebut: value,
          echeance: value  ,
           nbr_mois: item.dateFin !== '' ? calculateMonthDifference(value, item.dateFin  ) : calculateMonthDifference(value, new_input_datefin  ), 
           quantite: item.dateFin !== '' ? calculateMonthDifference(value, item.dateFin)*item.nbr_users : calculateMonthDifference(value, new_input_datefin)*item.nbr_users,
           dateFin: new_input_datefin
           };
      });
      setInputList(newlist);

    }
    if (filed === 'date_fin') {

      const newlist = inputList.map(item => {
        return { ...item, dateFin: value, nbr_mois: calculateMonthDifference(item.dateDebut, value),quantite:calculateMonthDifference(item.dateDebut, value)*item.nbr_users  };
      });
      setInputList(newlist);
    }
    if (filed === 'nb_mois') {
      const newDateFinContrat = calculateEndDate(entet_data.date_debut, value);
      set_entet_data(prev => ({
        ...prev,
        date_fin: newDateFinContrat
      }));
      const newlist = inputList.map(item => {
        return { ...item, 
           nbr_mois: calculateMonthDifference(item.dateDebut, newDateFinContrat  ) ,
           dateFin: newDateFinContrat
           };
      });
      setInputList(newlist);
    }


  }
  // getEntrepriseByCode
  const getAllSociete = async (e) => {
    // e.preventDefault();
    try {
      const response = await axios.get(api_url + "societe/" + getSession("code_generated"), config);
      return response;
    } catch (error) {
      console.error(error);
    }
  }



  const handleSelectChange = (e, index) => {
    if(client !==undefined || entet_data.client_code !=='') {
      const { value } = e.target;
      let position = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[position].text;
      const list = [...inputList];
      list[index]["produit_code"] = value;
      list[index]["libelleProduit"] = label;
      list[index]["prixHt"] = parseFloat(products[position - 1].thirdValue);
      list[index]["tvaProduct"] = client?.exonere === 1 ?   products[position - 1].fourthValue : 0;
      list[index]["suivie_stock"] = products[position - 1].suivieValue;
      list[index]["prix_achat_ht"] = products[position - 1].prix_achat_ht;
      list[index]["prix_achat_ttc"] = products[position - 1].prix_achat_ttc;
      setInputList(list);
    }else{
      toast.warning('choisir un client')
    }
   
  };



  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          {/** <h4 className="page-title">{t("Bon de livraison achat")}</h4>*/}
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addclient" className="btn btn-primary mr-3">
                {t("Ajouter client")}
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                {t("Ajouter produit")}
              </Link>
              <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header  border-0">
            <h4 className="card-title">{props.match.params.id ? t("Modifier abonnement") : t("Ajouter abonnement")} </h4>
          </div>
          <div className="card-body">
            <div className="content m-4">
              <ToastContainer></ToastContainer>
              <div className="row">
                <div className="col-sm-8 mb-4">
                  <h4 className="page-title"> Code Abonnement {entet_data.code_abonnement} </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>{t("Client")} : {fournisseurFullName}</label>

                        <Select onChange={handleChangeFournisseur} options={dataFournisseur} value={entet_data.client_code} />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>{t("email")} </label>
                        <input
                          type="email"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          required
                          value={entet_data.email}

                          onChange={(e) => handelchange_entet('email', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <label>{t("Durée du contrat par mois")}</label><span className="text-danger">*</span>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          aria-label="Text input with dropdown button"
                          required
                          value={entet_data.nb_mois}
                          onKeyPress={handleKeyPress_number}

                          onChange={(e) => handelchange_entet('nb_mois', parseInt(e.target.value))}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label>
                          {t("Fréquence de facturation en mois")}<span className="text-danger">*</span>
                        </label>
                        <input className="form-control"
                          type="text"
                          onChange={(e) => handelchange_entet('frequence_facturation', parseInt(e.target.value))}
                          value={entet_data.frequence_facturation}
                          required
                          onKeyPress={handleKeyPress_number}

                          />
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div className="form-group">
                        <label>
                          {t("Délais de rappel de jours")}<span className="text-danger">*</span>
                        </label>
                        <input className="form-control"
                          type="text"
                          onChange={(e) => handelchange_entet('Delais_rappel', parseInt(e.target.value))}
                          value={entet_data.Delais_rappel}
                          required
                          onKeyPress={handleKeyPress_number}

                        />
                      </div>
                    </div>
                  </div>
                 
                  <div className="row">

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>{t("Numéro de commande")}</label>
                        <input
                          className="form-control"
                          type="text"
                          value={entet_data.code_commande}
                          onChange={(e) => handelchange_entet('code_commande', e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date Début")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={moment(entet_data.date_debut).format("YYYY-MM-DD")}
                            onChange={(e) => handelchange_entet('date_debut', moment(e.target.value).format("YYYY-MM-DD"))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date Fin")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={moment(entet_data.date_fin).format("YYYY-MM-DD")}
                            onChange={(e) => handelchange_entet('date_fin', moment(e.target.value).format("YYYY-MM-DD"))}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date d'écheance")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={moment(entet_data.echeance).format("YYYY-MM-DD")}
                            onChange={(e) => handelchange_entet('echeance', moment(e.target.value).format("YYYY-MM-DD"))}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-responsive" id="mycustomtable">
                        <table className="table table-hover table-white">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t("Choisir produit")}</th>
                              <th>{t("Libelle")}</th>
                              <th>{t("Quantité")}</th>
                              <th>{t("Prix HT")}</th>
                              <th>{t("Remise")}</th>
                              <th>{t("Total HT")}</th>
                              <th>{t("TVA")} %</th>
                              <th> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputList.map((x, i) => {
                              const selectedProduct = products.find((product) => product.value === x.produit_code);
                              return (

                                <tr key={i}>
                                  <td>{i}</td>
                                  <td style={{ minWidth: 200, zIndex: 99 }}>
                                    <select className="form-control" value={x.produit_code} onChange={(e) => handleSelectChange(e, i)} style={{ marginTop: "-12px" }}>
                                      <option value={"default"}>{t("Choisir produit")}</option>
                                      {products
                                        .map((option) => (
                                          <option key={option.value} value={option.value}>
                                            {option.label}
                                          </option>
                                        ))}
                                    </select>
                                  </td>
                                  <td style={{ minWidth: 500, width: 450 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="libelleProduit"
                                      value={x.libelleProduit || libelleProduct}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />

                                    {selectedProduct && (
                                      <>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="date"
                                            name="dateDebut"
                                            value={moment(x?.dateDebut).format("YYYY-MM-DD")}
                                            onChange={(e) => handleInputChange(e, i)}
                                            style={{ width: "150px", marginLeft: "-8px" }}
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="date"
                                            name="dateFin"
                                            value={moment(x?.dateFin).format("YYYY-MM-DD")}
                                            onChange={(e) => handleInputChange(e, i)}
                                            style={{ width: "150px" }}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <input
                                            className="form-control"
                                            type="number"
                                            name="nbr_users"
                                            value={x?.nbr_users}
                                            onChange={(e) => handleInputChange(e, i)}
                                            style={{ width: "100px" }}
                                            placeholder="Nbr user"
                                            onKeyPress={handleKeyPress_number}

                                          />
                                        </td>
                                        <td>
                                          <p style={{ width: "100px" }}> nbr de mois</p>
                                          <p>{x?.nbr_mois || ''}</p>
                                        </td>
                                        <td ><p style={{ width: "100px" }}> Date d'écheance {' '}</p><p>{ moment(x.echeance).format("YYYY-MM-DD") || ''}</p></td>
                                      </>
                                    )}
                                  </td>

                                  <td style={{ minWidth: 70, width: 70 }}>
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="quantite"
                                      value={x.quantite}
                                      onChange={(e) => handleInputChange(e, i)}
                                      readOnly
                                    />
                                  </td>
                                  {/* <td>
                                <input
                                    className="form-control"
                                    type="number"
                                    name="num_serie"
                                    value={x.num_serie}
                                    onChange={(e) => handleInputChange(e, i)}
                                  />
                                   
                                </td> */}
                                  <td style={{ minWidth: 150, width: 200 }}>
                                    <input
                                  
                                      className="form-control"
                                      type="text"
                                      name="prixHt"
                                      value={x.prixHt}
                                      onChange={(e) => handleInputChange(e, i)}
                                      onKeyPress={handleKeyPress_number}

                                    />
                                  </td>
                                  <td style={{ minWidth: 250, width: 200 }}>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <select
                                          defaultValue="1"
                                          className="form-control btn btn-outline-secondary"
                                          name="actionRemise"
                                          value={x.actionRemise}
                                          onChange={(e) => handleInputChange(e, i)}
                                        >
                                          <option value="1">{t("Pourcent")}</option>
                                          <option value="2">{t("Montant")}</option>
                                        </select>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        aria-label="Text input with dropdown button"
                                        name="remise"
                                        value={x.remise}
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ minWidth: 150, width: 200 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      name="totalHt"
                                      value={x.totalHt}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                  </td>
                                  <td style={{ minWidth: 100, width: 200 }}>
                                    <input className="form-control" readOnly type="text" value={x.tvaProduct + " %"} />
                                  </td>
                                  <td>
                                    {inputList.length - 1 === i && (
                                      <Link
                                        to="#"
                                        className="text-success font-18 mr-2"
                                        title="Add"
                                        onClick={handleAddClick}
                                      >
                                        <i className="fa fa-plus" />
                                      </Link>
                                    )}
                                    {inputList.length !== 1 && (
                                      <Link
                                        to="#"
                                        className="text-danger font-18"
                                        title="remove"
                                        onClick={() => handleRemoveClick(i)}
                                      >
                                        <i className="fa fa-minus" />
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                            <div className="col-8">

                            </div>
                            <div className="col-4">
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("Total brut")}</strong>

                              </div>
                              <div className="col-4">
                                <span>      {entet_data.total_brut_Ht}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("Montant remise")}</strong>

                              </div>
                              <div className="col-4">
                                <span>      {entet_data.montant_remise}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("Total HT")}</strong>

                              </div>
                              <div className="col-4">
                                <span>      {entet_data.totalHt}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("Remise pied")}{actionRemiseEntete === "1" ? "%" : ""}</strong>

                              </div>
                              <div className="col-4">
                                <span>      {entet_data.remise_pied || 0}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("Net HT")}</strong>

                              </div>
                              <div className="col-4">
                                <span>      {entet_data.net_ht}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("montant  TVA")}</strong>

                              </div>
                              <div className="col-4">
                                <span>     {entet_data.montant_total_tva}</span>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-4">
                              <strong>{t("Total  TTC")}</strong>

                              </div>
                              <div className="col-4">
                                <span>     {entet_data.totalTtc}</span>
                              </div>
                            </div>
                            {(societe?.timbre_active === 1  ||entet_data.timbre  ) &&
                              <div className="row">
                              <div className="col-4">
                              <strong >{t("Timbre Fiscale")}</strong>

                              </div>
                              <div className="col-4">
                                <span>     {(entet_data.timbre && entet_data.timbre !==0 )? entet_data.timbre  : societe?.timbre_fiscale}</span>
                              </div>
                            </div>
                              }
                           <div className="row">
                              <div className="col-4">
                              <strong>{t("Net a payer")}</strong>

                              </div>
                              <div className="col-4">
                                <span>     {entet_data.net_a_payer}</span>
                              </div>
                            </div>
                            </div>
                      </div>
                  

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{t("Ajouter note")}</label>
                            <textarea
                              onChange={(e) => handelchange_entet('note', e.target.value)}
                              className="form-control"
                              value={entet_data.note}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row no-print mt-4">
                    <div className="col-12">
                      {/* <a
                href="invoice-print.html"
                rel="noopener"
                target="_blank"
                className="btn btn-default"
              >
                <i className="fas fa-print" /> Print
              </a> */}
                      <button type="button" className="btn btn-primary float-right" onClick={handleSaveEntete} >
                        <i className="far fa-credit-card" /> {t("Enregistrer")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-info float-right"
                        style={{ marginRight: 5 }}
                        onClick={handleShowFacture}
                      >
                        <i className="fas fa-file-invoice" /> {t("Afficher bon de livraison")}
                      </button>
                    </div>
                  </div>

                  {/* <button className="btn btn-primary" onClick={handleSaveEntete}>
            Enregister
          </button>
          <button className="btn btn-info">Afficher facture</button> */}

                  <div className="row mt-4">
                    <div className="col-12">
                      {showResults ? (
                        <div style={{ width: "100%", height: 800 }}>
                          <MyDocument data={objectToPdf} inputList={inputList} t={t} />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditerAbonnement;

import React, { useEffect, useState } from 'react'
import {  addType_contrat, getsouche_Type_contrat,  updateType_contrat } from '../../../Services/Pointeuse/EntrepriseApi';

import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { toast } from 'react-toastify';

export default function ContratTypeForm({ objdata, getData }) {

    const [modif, setmodif] = useState(false)
    const [data, setdata] = useState(
        {
            code: '',
            code_generated: localStorage.getItem('code_generated'),
            libelle: '',
            cnss: 0,
            irpp: 0

        }
    )

    useEffect(() => {
        if (objdata && objdata?.id) {
            setmodif(true)
            setdata({
                id: objdata.id,
                code: objdata.code,
                code_generated: localStorage.getItem('code_generated'),
                libelle: objdata.libelle,
                cnss: objdata.cnss,
                irpp: objdata.irpp
            })
        } else {
            souche()

        }
    }, [objdata])
    const souche = () => {
        getsouche_Type_contrat().then((res) => {

            if (res?.data) {
                if (res.data.last_code) {
                    const lastsouche = res.data.last_code.split(res.data.seq).pop();

                    setdata(prev => ({
                        ...prev,
                        code: `${res.data.seq}${parseInt(lastsouche) + 1}`,

                    }));
                }
                else {
                    setdata(prev => ({
                        ...prev,
                        code: `${res.data.seq}1`,

                    }));
                }
            }
        })
    }

    const handelchange = (name, value) => {
        const copiedata = { ...data }
        copiedata[name] = value
        setdata(copiedata)

    }

    const handelsave = () => {
        const newdata = { ...data };
        if (data && data?.id) {

            updateType_contrat(newdata).then((res) => {
                if (res.status === 200) {
                    toast.success(t("type contract est modifié"))

                    setdata({
                        code_generated: localStorage.getItem('code_generated'),
                        libelle: '',
                        cnss: 0,
                        irpp: 0
                    });
                    souche();

                    getData();
                }
            });
        } else {
            addType_contrat(newdata).then((res) => {
                if (res.status === 201) {
                    toast.success(t("type contract est enregistrée"))

                    setdata({
                        code_generated: localStorage.getItem('code_generated'),
                        libelle: '',
                        libelle: '',
                        cnss: 0,
                        irpp: 0
                    });
                    souche()
                    getData();
                }
            });
        }


    }
    return (
        <div>


            <div className="row">
                <div className="col-xl-12">
                    <div className="tab-content adminsetting-content" id="setting-tabContent">
                        <div className="tab-pane fade show active" id="tab-1" role="tabpanel">
                            <div className="card">

                                <div className="card-body">

                                    <div className='row  mt-3'>
                                        <label>{t('Code')}</label>
                                        <input type='text' className="form-control" value={data.code} readOnly />
                                    </div>

                                    <div className='row  mt-3'>
                                        <label>{t('Libelle')}</label>
                                        <input type='text' readOnly={modif} className="form-control" value={data.libelle} onChange={(e) => handelchange('libelle', e.target.value)} />
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-4'>
                                        <label>{t('CNSS')}</label>
                                        <input type='checkbox' readOnly={modif}  value={data.cnss} onChange={(e) => handelchange('cnss', data.cnss ===1 ? 0 :1)} />
                                        </div>
                                        <div className='col-4'>
                                        <label>{t('IRPP')}</label>
                                        <input type='checkbox' readOnly={modif} value={data.irpp} onChange={(e) => handelchange('irpp', data.irpp ===1 ? 0 :1)} />
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer float-md-right">
                                {modif ? (
                                    <Link to="#" onClick={() => setmodif(false)} className="btn btn-primary float-md-right">
                                        {t("Modifier")}
                                    </Link>
                                ) :
                                    <Link to="#" onClick={handelsave} className="btn btn-primary float-md-right">
                                        {t("Enregistrer")}
                                    </Link>}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

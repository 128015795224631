import React, { useEffect, useState } from 'react'
import { changeactive, getparam_rub_paie, getuser_rubcode } from '../../../Services/paie/parametrage_paie';
import Pagination from '../../Others/Pagination';
import ExportCsv from '../../Exports/ExportCsv';
import { useTranslation } from 'react-i18next';
import { Link } from '@react-pdf/renderer';
import CopieContent from '../../Exports/CopieContent';
import ErrorCustum from '../../Others/ErrorCustum';
import CustomSyncLoader from '../../Others/CustomSyncLoader';
import { useHistory } from "react-router-dom";
import ButtonHeaderComponent from '../../Styles/ButtonHeaderComponent';
import { toast } from 'react-toastify';
import AjoutBanque from './AjoutBanque';
import { getbanques } from '../../../Services/banque';
import { getAllDevise } from '../../../Services/devise/devise';

export default function List_banques({ refresh }) {
    const { t } = useTranslation(); // Utilisez le hook useTranslation pour obtenir la fonction de traduction t
    let history = useHistory();
    const [currentData, setcurrentData] = useState([]);
    const [allInitialData, setallInitialData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setdata] = useState(null);

    const [size, setsize] = useState(40);
    const [isCollapsed, setisCollapsed] = useState(false);

    const [dataobj, setdataobj] = useState()
    const [showModal, setshowModal] = useState(false)
    const [list_rub_user, setlist_rub_user] = useState([])

    const [deviseState, setdeviseState] = useState([])

    const handleChangeSize = (e) => {
        setsize(e.target.value);
        getData();
    };

    useEffect(() => {
        getAllDevise().then((res) => {
            if (res?.status === 200) {
                setdeviseState(res?.data?.data)
            }
        });
    })
    useEffect(() => {
        getData();
    }, [refresh]);

    const getData = () => {
        setLoading(true);
        getbanques().then((res) => {
            setallInitialData(res?.data);
            setdata(res?.data);
            setLoading(false)
        });

    };

    const onChangePage = (pageOfItems) => {
        // update state with new page of items
        setcurrentData(pageOfItems);
    };
    const handleSearch = () => { }


    const handleActive = (el) => {
        const data = { code: el.code }
        changeactive(data).then((res) => {
            if (res.status === 200) {
                toast.success(t("L'etat du rubrique est changé"))
                getData()
            }

        })


    }

    const user_rubcode = (code) => {
        getuser_rubcode(code).then((res) => {
            if (res.status === 200) {
                setlist_rub_user(res.data)
            }
        })
        setshowModal(true)

    }

    return (
        <div>
            <>

                <div className="page-header d-xl-flex d-block">
                    <div className="page-leftheader">
                        <h4 className="page-title">{t("Parametrage Rubriques")}</h4>
                    </div>
                    <div className="page-rightheader ml-md-auto">
                        <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                            <div className="btn-list">

                                <ButtonHeaderComponent />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12 col-md-12 col-lg-12">
                        <div className={isCollapsed ? "card  card-fullscreen" : "card"}>
                            <div className="card-header  border-0">
                                <h4 className="card-title">{t("List  des banques ")}</h4>


                            </div>

                            <div className="card-body row">


                                {loading && <CustomSyncLoader></CustomSyncLoader>}
                                {!loading && error && <ErrorCustum></ErrorCustum>}
                                {!loading && !error && data && (
                                    <div className="table-responsive col-7">
                                        <div id="hr-table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                            <div className="row ">
                                                <div className="col-sm-12 col-md-6">
                                                    <div className="dataTables_length" id="hr-table_length">
                                                        <label>
                                                            {t("Afficher")}{" "}
                                                            <select value={size} className="form-control" id="exampleFormControlSelect1" onChange={handleChangeSize}>
                                                                <option value={8}>8</option>
                                                                <option value={20}>20</option>
                                                                <option value={40}>40</option>
                                                                <option value={60}>60</option>
                                                                <option value={100}>100</option>
                                                            </select>{" "}
                                                            {t("entrées")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-6">
                                                    <div id="hr-table_filter" className="dataTables_filter">
                                                        <label className="mr-2">
                                                            <input
                                                                type="search"
                                                                className="form-control"
                                                                placeholder={t("Recherche...")}
                                                                aria-controls="hr-table"
                                                                onChange={handleSearch}
                                                            />
                                                        </label>

                                                        <ExportCsv
                                                            data={data}
                                                            name={"List_exercices"}
                                                            columns={[
                                                                'code',
                                                                "libelle",
                                                                "presence",
                                                                "cnss",
                                                                "irpp",
                                                                'plafond',
                                                                "montant_plafond",
                                                                "compte_comptable",
                                                                'type'

                                                            ]}
                                                        />
                                                        <CopieContent />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row c">
                                                <div className="col-sm-12">
                                                    <table
                                                        className="table table-vcenter text-nowrap table-bordered border-bottom  no-footer"
                                                        id="hr-table"
                                                        role="grid"
                                                        aria-describedby="hr-table_info"
                                                    >
                                                        <thead>
                                                            <tr >
                                                                <th className="border-bottom-0" >{t("Banque")}</th>

                                                                <th className="border-bottom-0">{t("Num de compte")}</th>
                                                                <th className="border-bottom-0">{t("devise")}</th>




                                                                <th className="border-bottom-0" >
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {currentData.map((el) => (

                                                                <tr key={el.id}>

                                                                    <td>{el.banque}{' '}{el.active === 1 && <span style={{ color: 'green' }}>active</span>}</td>

                                                                    <td>{el.num_compte}</td>
                                                                    <td>   {deviseState.find(item=>item.id ===el.devise_id)?.libelle}
                                                                    </td>




                                                                    <td >
                                                                        {true && (
                                                                            <div className="btn-group">
                                                                                <button
                                                                                    style={{ textAlign: "center", padding: 2, width: 30 }}
                                                                                    type="button"
                                                                                    className="btn btn-secondary dropdown-toggle dropdown-icon"
                                                                                    data-toggle="dropdown"
                                                                                ></button>
                                                                                <div className="dropdown-menu" role="menu">
                                                                                    <Link to="#" onClick={() => setdataobj(el)} className="dropdown-item">
                                                                                        {t("Editer")}
                                                                                    </Link>
                                                                                   {/* <Link to="#" onClick={() => handleActive(el)} className="dropdown-item">
                                                                                        {el.active === 1 ? t('Desactiver') : t('Activer')}
                                                                                    </Link>*/}


                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <th className="border-bottom-0" >{t("Banque")}</th>
                                                                <th className="border-bottom-0">{t("Num de compte")}</th>
                                                                <th className="border-bottom-0">{t("devise")}</th>
                                                                <th className="border-bottom-0" >
                                                                </th>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-12 col-md-5">
                                                    <div className="dataTables_info" id="hr-table_info" role="status" aria-live="polite">
                                                        {t("Affichage")} {t("de")} 1 {t("à")} {size} {t("sur")} {data.length} {t("entrées")}
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-7">
                                                    <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}

                                <div className='col-5'>
                                    <AjoutBanque data={dataobj} deviseState={deviseState} getData={getData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    )
}




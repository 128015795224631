import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * It gets a client by id.
 * @param id - The id of the client you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "client": {
 *       "id": 1,
 *       "nom": "Dupont",
 *       "prenom": "Jean",
 *       "adresse": "1 rue de la paix",
 */
export async function getClientById(id) {
  try {
    const response = await axios.get(api_url + "facturation/client/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}



/**
 * It gets a client by code id.
 * @param code - The client code.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "client": {
 *       "codeId": "123456789",
 *       "name": "Client Name",
 *       "address": "Client Address",
 *       "city": "Client City",
 *       "country": "Client Country",
 *       "post
 */
export async function getClientByCodeId(code) {
  try {
    const response = await axios.get(api_url + "facturation/client/codeid/" + code, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * It gets the facturation client by code.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code": "",
 *   "client": {
 *     "code": "",
 *     "name": "",
 *     "address": "",
 *     "city": "",
 *     "country": "",
 *     "email": "",
 *     "phone": "",
 */
export async function getFacturationClientByCode() {
  try {
    const response = await axios.get(api_url + "facturation/client/code/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getClientSupportByCode() {
  try {
    const response = await axios.get(api_url + "facturation/client/clientsupport/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**
 * Get the facturation of a client by its code
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code_fournisseur": "123456789",
 *   "code_client": "123456789",
 *   "date_facturation": "2020-01-01",
 *   "montant_ht": "123456789",
 *   "taux_
 */
export async function getFacturationFournisseurByCode() {
  try {
    const response = await axios.get(api_url + "facturation/client/codefournisseur/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/**** clients */
export async function getClientsByCodeS() {
  try {
    const response = await axios.get(api_url + "facturation/client/clients/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Get the facturation of a client by its code
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code_fournisseur": "123456789",
 *   "code_client": "123456789",
 *   "date_facturation": "2020-01-01",
 *   "montant_ht": "123456789",
 *   "taux_
 */
export async function getFacturationClientsByCode() {
  try {
    const response = await axios.get(api_url + "facturation/client/getallclients/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getAllClientsFactureAbonnement() {
  try {
    const response = await axios.get(api_url + "facturation/client/getallclients/facture/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


export async function getAllClientsparType() {
  try {
    const response = await axios.get(api_url + "facturation/client/getallclients/type/"  , config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
/**
 * Get the facturation of a client by its code
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "code_fournisseur": "123456789",
 *   "code_client": "123456789",
 *   "date_facturation": "2020-01-01",
 *   "montant_ht": "123456789",
 *   "taux_
 */
export async function getAllClientsFactures() {
  try {
    const response = await axios.get(api_url + "facturation/client/getallclientsfactures/" + getSession("code_generated"), config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Add a client to the database
 * @param code - The code of the client.
 * @param activite_code - The code of the activity of the client.
 * @param statut - The status of the client.
 * @param tarification - 0 for normal, 1 for tarif bleu
 * @param magasin - The store code
 * @param code_tva - The code of the VAT rate.
 * @param potentiel - The client's potential.
 * @param potentiel_societe - The client's potential.
 * @param nom - The client's name.
 * @param prenom - The client's first name.
 * @param cin - The CIN of the client.
 * @param email - The email of the client.
 * @param anniversaire - date of birth
 * @param fixe - The phone number of the client.
 * @param mobile - The mobile number of the client.
 * @param description - Description of the client
 * @param rib - RIB
 * @param solde_releve - The balance of the client's account.
 * @param code_erp - The code of the client in the ERP system.
 * @param tax - 0 or 1
 * @param adresse_facturation - The address of the client.
 * @param localite - The city where the client is located.
 * @param region - The region of the client.
 * @param gouvernorat - The name of the Governorate.
 * @param secteur - The sector of the client.
 * @param zone - The zone of the client.
 * @param categorie - The category of the client.
 * @param categoriecommercial_code - The code of the category commercial.
 * @param specialite - The speciality of the client.
 * @param regime_fiscale - The fiscal regime of the client.
 * @param matricule_fiscale - The fiscal number of the client.
 * @param commentaire - commentaire
 * @param image - The image of the client.
 * @param remise_client - The discount percentage for the client.
 * @param delai_paiement - The number of days before the due date of the invoice.
 * @param plafond_credit - The maximum amount of credit that the client can have.
 * @param rc - 0 or 1
 * @returns The response is an object that contains the data that was returned from the server.
 */
export async function addFacturationClient(
  code,
  deviseselected,
  exonere,
  activite_code,
  plafontCredit,
  statut,
  tarification,
  magasin,
  code_tva,
  potentiel,
  potentiel_societe,
  nom,
  prenom,
  cin,
  email,
  anniversaire,
  fixe,
  mobile,
  description,
  rib,
  solde_releve,
  code_erp,
  tax,
  adresse_facturation,
  localite,
  region,
  gouvernorat,
  secteur,
  zone,
  categorie,
  categoriecommercial_code,
  specialite,
  regime_fiscale,
  matricule_fiscale,
  commentaire,
  image,
  remise_client,
  delai_paiement,
  plafond_credit,
  rc
) {
  const frmData = new FormData();

  frmData.append("code", code);
  frmData.append("activite_code", activite_code);
  frmData.append("user_code", getSession("id"));
  frmData.append("soussociete_code", getSession("code_generated"));
  frmData.append("plafontCredit", plafontCredit);
  frmData.append("nom", nom);
  frmData.append("prenom", prenom);
  frmData.append("statut", statut);
  frmData.append("virtuelle", "0");
  frmData.append("description", description);
  frmData.append("routing_code", "");
  frmData.append("tarification", tarification);
  frmData.append("magasin", magasin);
  frmData.append("code_tva", code_tva);
  frmData.append("code_erp", code_erp);
  frmData.append("tax", tax);
  frmData.append("adresse_facturation", adresse_facturation);
  frmData.append("email", email);
  frmData.append("anniversaire", anniversaire);
  frmData.append("potentiel", potentiel);
  frmData.append("potentiel_societe", potentiel_societe);
  frmData.append("fixe", fixe);
  frmData.append("mobile", mobile);
  frmData.append("latitude", "");
  frmData.append("longitude", "");
  frmData.append("rib", rib);
  frmData.append("isactif", "");
  frmData.append("bloque", "");
  frmData.append("solde_releve", solde_releve);
  frmData.append("engagement_client", "");
  frmData.append("encours_actuelement", "0");
  frmData.append("autorisation", "");
  frmData.append("plafond_credit", plafond_credit);
  frmData.append("exception_credit", "0");
  frmData.append("encours_actuelement_cheque", "0");
  frmData.append("autorisation_cheque", "0");
  frmData.append("plafond_credit_cheque", "0");
  frmData.append("encours_actuelement_traite", "0");
  frmData.append("autorisation_traite", "0");
  frmData.append("plafond_credit_traite", "0");
  frmData.append("autorisation_paiement_instance", "0");
  frmData.append("plafond_espece", "0");
  frmData.append("delai_paiement", delai_paiement);
  frmData.append("ticket_cadeaux", "0");
  frmData.append("rc", rc);
  frmData.append("cin", cin);
  frmData.append("localite", localite);
  frmData.append("region", region);
  frmData.append("gouvernorat", gouvernorat);
  frmData.append("secteur", secteur);
  frmData.append("zone", zone);
  frmData.append("categorie", categorie);
  frmData.append("categoriecommercial_code", categoriecommercial_code);
  frmData.append("specialite", specialite);
  frmData.append("regime_fiscale", regime_fiscale);
  frmData.append("fidelitePoint", "0");
  frmData.append("matricule_fiscale", matricule_fiscale);
  frmData.append("remember_token", "");
  frmData.append("presentoire", "");
  frmData.append("passager", "");
  frmData.append("refrigerateur", "");
  frmData.append("acces_metrage", "0");
  frmData.append("wavesoft", "");
  frmData.append("changement_prix", "");
  frmData.append("code_sms", "");
  frmData.append("issync", "0");
  frmData.append("autorisation_espece", "0");
  frmData.append("remise_client", remise_client);
  frmData.append("commentaire", commentaire);
  frmData.append("date_creation", "2020-01-07");
  frmData.append("deleted_at", "2020-01-07");
  frmData.append("created_at", "");
  frmData.append("updated_at", "2020-01-07");
  frmData.append("image", image);
  frmData.append("devise", deviseselected);
  frmData.append("exonere", exonere);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.post(api_url + "facturation/client/", frmData, options);
    console.log(response);
    toast.success(t("Client ajouté"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Update a client by id
 * @param code - The code of the client.
 * @param codeActivity - The code of the activity of the client
 * @param status - The status of the client.
 * @param tarif - the client's tarification
 * @param magasin - the store code
 * @param tva - the tva code
 * @param potentiel - the client's potential
 * @param potentielSoc - the client's potential
 * @param nom - the client's name
 * @param prenom - the client's first name
 * @param cin - cin
 * @param email - email,
 * @param selectedDate - Date of birth
 * @param fixe - The phone number of the client.
 * @param mobile - the mobile number of the client
 * @param description - Description of the client
 * @param rib - the RIB of the client
 * @param soldeReleve - The balance of the client's account.
 * @param codeErp - The ERP code of the client
 * @param tax - tax,
 * @param adrFacturation - adresse de facturation
 * @param localite - localite,
 * @param region - The region of the client.
 * @param gouvernorat - the name of the gouvernorat
 * @param secteur - secteur
 * @param zone - The zone of the client.
 * @param categorie - the category of the client
 * @param codeCommercialCategorie - The code of the category of the client.
 * @param speciality - speciality
 * @param regimeFiscal - "",
 * @param matriculeFiscal - Matricule fiscal
 * @param commentaire - commentaire,
 * @param remise - The discount percentage
 * @param delaiPaiement - The number of days before the due date of the invoice.
 * @param plafondCredit - The maximum amount of credit that the client can have.
 * @param rc - rc,
 * @param id - id,
 * @returns The response is an object that contains the data that was returned from the server.
 */
export async function updateClientById(
  code,

  deviseselected,
  exonere,
  rs,
  codeActivity,
  status,
  tarif,
  magasin,
  tva,
  potentiel,
  potentielSoc,
  nom,
  prenom,
  cin,
  email,
  selectedDate,
  fixe,
  mobile,
  description,
  rib,
  soldeReleve,
  codeErp,
  tax,
  adrFacturation,
  localite,
  region,
  gouvernorat,
  secteur,
  zone,
  categorie,
  codeCommercialCategorie,
  speciality,
  regimeFiscal,
  matriculeFiscal,
  commentaire,
  remise,
  delaiPaiement,
  plafondCredit,
  rc,
  id
) {
  const credentiel = {
    code: code,
    activite_code: codeActivity,
    user_code: getSession("id"),
    soussociete_code: getSession("code_generated"),
    nom: nom,
    prenom: prenom,
    statut: status,
    virtuelle: "0",
    description: description,
    routing_code: "",
    tarification: tarif,
    magasin: magasin,
    code_tva: tva,
    code_erp: codeErp,
    tax: tax,
    adresse_facturation: adrFacturation,
    email: email,
    anniversaire: selectedDate,
    potentiel: potentiel,
    potentiel_societe: potentielSoc,
    fixe: fixe,
    mobile: mobile,
    latitude: "",
    longitude: "",
    rib: rib,
    isactif: "",
    bloque: "",
    solde_releve: soldeReleve,
    engagement_client: "",
    encours_actuelement: "0",
    autorisation: "",
    plafond_credit: plafondCredit,
    exception_credit: "0",
    encours_actuelement_cheque: "0",
    autorisation_cheque: "0",
    plafond_credit_cheque: "0",
    encours_actuelement_traite: "0",
    autorisation_traite: "0",
    plafond_credit_traite: "0",
    autorisation_paiement_instance: "0",
    plafond_espece: "0",
    delai_paiement: delaiPaiement,
    ticket_cadeaux: "0",
    rc: rc,
    cin: cin,
    localite: localite,
    region: region,
    gouvernorat: gouvernorat,
    secteur: secteur,
    zone: zone,
    categorie: categorie,
    gouvernerat: gouvernorat,
    categoriecommercial_code: "",
    specialite: speciality,
    regime_fiscale: regimeFiscal,
    fidelitePoint: "0",
    matricule_fiscale: matriculeFiscal,
    remember_token: "",
    presentoire: "",
    passager: "",
    refrigerateur: "",
    acces_metrage: "0",
    wavesoft: "",
    changement_prix: "",
    code_sms: "",
    issync: "0",
    autorisation_espece: "0",
    remise_client: remise,
    commentaire: commentaire,
    updated_at: "",
    id: id,
    devise_id:deviseselected,
    exonere:exonere,
    rs_id:rs
  };
  try {
    const response = await axios.patch(api_url + "facturation/client/", credentiel, config);
    console.log(response);
    toast.success(t("modifié"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Get a client by id
 * @param id - The id of the client you want to get.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "client": {
 *       "id": 1,
 *       "nom": "Dupont",
 *       "prenom": "Jean",
 *       "adresse": "1 rue de la paix",
 *       "codePostal": "75
 */
export async function getClientFournisseurByid(id) {
  try {
    const response = await axios.get(api_url + "facturation/client/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Update an image of a client by id
 * @param image - The image file that you want to upload.
 * @param id - The id of the client to update.
 * @returns The response from the server.
 */
export async function updateImageClientById(image, id) {
  const frmData = new FormData();
  frmData.append("id", id);
  frmData.append("image", image);

  const httpHeaders = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + getToken(),
  };

  const options = {
    headers: httpHeaders,
  };

  try {
    const response = await axios.put(api_url + "facturation/client/image/", frmData, options);
    toast.success(t("mise à jour"));
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Delete a client by id
 * @param id - The id of the client to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "status": "success",
 *   "data": {
 *     "id": "1",
 *     "nom": "Dupont",
 *     "prenom": "Jean",
 *     "adresse": "1 rue de la paix",
 *     "ville": "
 */
export async function deleteClientById(id) {
  try {
    const response = await axios.delete(api_url + "facturation/client/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}


/***
 * updateInfoFacture
 */
export async function updateInfoFacture(idfacture) {
  try {
    const response = await axios.put(api_url + "facturation/client/updateinfoFacture/" + idfacture, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
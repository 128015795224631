import React, { useEffect, useState } from "react";
import { PDFViewer, PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import {

  AjoutEntete_ligne,
  GetEntete_ligne,
  UpdateEntete_ligne,
  editSendEmailFacture,
  getEnteteByCode,

} from "../../../../../Services/Facturation/FacturationEnteteApi";
import { addLigne, deleteLigneByCodeEntete, getLigneByCode } from "../../../../../Services/Facturation/FacturationLigneApi";
import { getSequenceByCode } from "../../../../../Services/Facturation/FacturationSequenceApi";
import { getClientByCodeId, getClientById, getClientsByCodeS, getFacturationClientByCode, updateInfoFacture } from "../../../../../Services/Facturation/FacturationClientApi";
import { getAllProduct, getAllProductNumseries, getAllProductVente, getProduitById } from "../../../../../Services/Facturation/FacturationProduitApi";
import { getEntrepriseByCode } from "../../../../../Services/Pointeuse/EntrepriseApi";

import MyDocument from "../../../invoiceReports/MyDocument";

import { useHistory } from "react-router-dom";
import { sendFactureBlob } from "../../../../../Services/Facturation/FacturationFileApi";
import { getToken, getSession } from "../../../../../Utils/SessionUtils";

import swal from "sweetalert";
import axios from "axios";
import { t } from "i18next";
import Facture from "../../../invoiceReports/Facture";
import Tabletva from "../../../TableTVA/Tabletva";
import { MultiplicateNumber, calculateRemise } from "../../../../../Utils/NumberUtils";
import { getLastfactureVenteID } from "../../../../../Services/sequances/sequances";
import { getAllDevise } from "../../../../../Services/devise/devise";
import ReactSelect from "react-select";
import { generateUniqueCode } from "../../../../../Utils/helper";
import { checkEmptyPrice_Quantity } from "../../../../../Utils/ObjectOperation";
import { getAllTva } from "../../../../../Services/Facturation/FacturationTvaApi";
import zIndex from "@mui/material/styles/zIndex";
function EditerFactureVente(props) {
  const history = useHistory();
  const api_url = process.env.REACT_APP_API_BASE_URL_V1;
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  //show facture
  const [showResults, setShowResults] = useState(false);
  const [showResultsEmail, setShowResultsEmail] = useState(false);
  const [dataFournisseur, setDataFournisseur] = useState([]);

  const [listTva, setlistTva] = useState([]);
  //product detail
  const [products, setProducts] = useState([]);
  const [idtoremove, setlisttoremove] = useState([])

  const [inputValue, setInputValue] = useState([]);

  //code entete
  const [objectToPdf, setobjectToPdf] = useState();

  // client detail

  const [emailClient, setEmailClient] = useState("");


  // facture detail

  // facture footer
  const [noteFacture, setNoteFacture] = useState("");

  // entete

  const [solde, setsolde] = useState("");
  const [numBl, setnumBl] = useState("");
  const [montantTotalTTC, setmontantTotalTTC] = useState("");
  const image_url = process.env.REACT_APP_API_URL;
  const [listTva_societe, setlistTvaSociete] = useState([]);
  const [client, setclient] = useState()
  const [abonnementFacture, setabonnementFacture] = useState([]);
  const [nbSendedEmail, setnbSendedEmail] = useState(null);
  const [abonnementFields, setAbonnementFields] = useState([]);
  const [devises, setlistdevise] = useState([])

  const [societe, setsociete] = useState()
  const [deviseclient, setdeviseclient] = useState()
  const [entetfacture, setentetfacture] = useState({
    type: 'facture',
    document: 'vente',
    code: '',
    code_commande: '',
    numero_bl: '',
    facture_code: '',
    code_fournisseur: '',
    adresse: '',
    code_abonnement: null,
    sended_email: 0,
    societe_code: localStorage.getItem('code_generated'),
    user_code: localStorage.getItem('code'),
    commercial_code: localStorage.getItem('code'),
    client_code: '',
    client_nom: '',
    date: moment(new Date()).format("YYYY-MM-DD"),
    timbre: 0,
    montant_total_ht: 0,
    montant_total_ttc: 0,
    montant_total_tva: 0,
    remise_total: 0,
    retenue_source: 0,
    net_a_payer: 0,
    net_a_payer_ht: 0,
    remise_pied: 0,
    remise_action: '1',
    date_echeance: null,
    commentaire: '',
    retenue_source: '',
    code_piece_pre: '',
    
  })
  // dynamic list product
  const [inputList, setInputList] = useState([
    {
      id: '',
      produit_code: "",
      quantite: 0,
      pu_ht: 0,
      total_brut_ht: 0,
      total_ht: 0,
      total_net_ht: 0,
      montant_tva: 0,
      pu_ttc: 0,
      taux_tva: 0,
      prix_achat_ht: 0,
      prix_achat_ttc: 0,
      taux_tva: 0,
      remise: 0,
      remise_client: 0,
      email: '',
      libelleProduit: '',
      actionRemise: '1',
      suivieStock: '',
      code_abonnement: '',
      num_serie: [],
      exonere: ''
    },
  ]);
  // fetch data
  useEffect(() => {
    getClientsByCodeS().then((res) => {
      const result = res.data;
      const options = result.map((d) => ({
        value: d.code,
        label: d.nom + " " + d.prenom,
        secondValue: d.id,
        remise_client: d.remise_client,
        exonere: d.exonere,
        rs_id: d.rs_id,
        devise_id: d.devise_id,
        mobile:d.mobile,
        adresse_facturation: d.adresse_facturation,
        matricule_fiscale:d.matricule_fiscale,
        email: d.email,
        image: d.image
      }));

      setDataFournisseur(options);
    });

  }, []);
  useEffect(() => {
    getAllProductVente().then((res) => {
      const result = res.data;
      const newlist = result.map(element => {
        if (element.listnumseries && element.listnumseries.length !== 0) {
          element.options = element.listnumseries.map((d, index) => ({
            value:  d.numero_serie,
            label: d.numero_serie,
          }));
        }
        return element; // Return the modified element
      });


      setProducts(newlist);
    });
    let societedata
    getEntrepriseByCode().then((res) => {
      societedata=res.data
      setsociete(res.data)
      
      setentetfacture(prev => ({
        ...prev,
        timbre: res.data.timbre_active === 1 ? res.data.timbre_fiscale : 0,

      }));
    });
    getAllDevise().then((res) => {
      if (res.data) {

        setlistdevise(res.data.data)
      
        const deviseid =  societedata?.devise ||0;
        setdeviseclient(res.data.data.find(item => item.id === deviseid))


      }
    });
  }, [])
  
  useEffect(() => {
    getAllTva().then((res) => {
      if (res.data) {
        setlistTvaSociete(res.data)
      }
    });

  }, []);
  useEffect(() => {
    let listtva = []
    inputList.forEach((element, index) => {
      const data_tva = {
        index: index,
        tva: element.taux_tva,
        prix: element.total_net_ht,

      };
      listtva.push(data_tva)
    });
    setlistTva(listtva)

  }, [inputList]);

  useEffect(() => {

    const new_total_brut = inputList.reduce((ac, val) => ac + parseFloat(val.total_brut_ht), 0);
    const new_net_ht = inputList.reduce((ac, val) => ac + parseFloat(val.total_net_ht), 0);
   
    let new_montant_total_tva = 0
    inputList.forEach(element => {
      //const remise = calculateRemise(element.total_net_ht, entetfacture.remise_pied, entetfacture.remise_action);
      const montattva = parseFloat(element.total_net_ht) * (element.taux_tva / 100)
      new_montant_total_tva = parseFloat(new_montant_total_tva) + parseFloat(montattva)
    });
  
    const montant_ttc = parseFloat(new_net_ht) + parseFloat(new_montant_total_tva);

    const net_a_payer = montant_ttc + entetfacture.timbre;
    setentetfacture(prev => ({
      ...prev,
      montant_total_ht: parseFloat(new_total_brut).toFixed(deviseclient?.devision || 3),
      net_a_payer_ht: parseFloat(new_net_ht).toFixed(deviseclient?.devision || 3),
      remise_total: (parseFloat(new_total_brut) - parseFloat(new_net_ht)).toFixed(deviseclient?.devision || 3),

      montant_total_ttc: parseFloat(montant_ttc).toFixed(deviseclient?.devision || 3),
      net_a_payer: parseFloat(net_a_payer).toFixed(deviseclient?.devision || 3),
      montant_total_tva: parseFloat(new_montant_total_tva).toFixed(deviseclient?.devision || 3)

    }));
  }, [inputList]);



  useEffect(() => {
    if (props.match.params.id) {
      GetEntete_ligne(props.match.params.id).then((res) => {

        if (res?.data) {
          const entetdata = res.data.entet;
          const lignesdata = res.data.lignes;

          const entet_data = {
            code: entetdata.code,
            code_commande: entetdata.code_commande,
            facture_code: entetdata.facture_code,
            numero_bl: entetdata.numero_bl,
            user_code: entetdata.user_code,
            client_code: entetdata.client_code,
            client_nom: entetdata.client_nom,
            code_fournisseur: entetdata.code_fournisseur,
            timbre: entetdata.timbre,
            adresse: entetdata.adresse,
            commentaire: entetdata.commentaire,
            code_abonnement: entetdata.code_abonnement,
            sended_email: entetdata.sended_email,
            net_a_payer: entetdata.net_a_payer,
            montant_total_ttc: entetdata.montant_total_ttc,
            remise_total: entetdata.remise_total,
            montant_total_tva: entetdata.montant_total_tva,
            net_a_payer_ht: entetdata.net_a_payer_ht,
            montant_total_ht: entetdata.montant_total_ht,
            type: 'facture',
            document: 'vente',
            societe_code: localStorage.getItem('code_generated'),
            commercial_code: entetdata.commercial_code,
            date: entetdata.date.split('T')[0],
            retenue_source: entetdata.retenue_source,
            remise_pied: entetdata.remise_pied,
            remise_action: entetdata.remise_action,
            date_echeance: entetdata.date_echeance !== null ? entetdata.date_echeance.split('T')[0] : null,
            commentaire: entetdata.commentaire,
            code_piece_pre: entetdata.code_piece_pre,
            type_piece_pre: entetdata.type_piece_pre,
            converted_to: entetdata.converted_to,
            type_converted_to: entetdata.type_converted_to,
            exonere: dataFournisseur?.find(c => c.value === entetdata.client_code)?.exonere || '',
            client_mf:entetdata?.client_mf,
            client_mobile:entetdata?.client_mobile,
            client_adresse_facturation: entetdata?.client_adresse_facturation,
            client_email:entetdata?.client_email,
          
          }
          const findclient = dataFournisseur?.find(item => item.code === entetdata.client_code)
          if(findclient&& findclient.devise_id ){
            setdeviseclient(devises.find(item => item.id === findclient.devise_id ))
          }
        
        
         
          setclient(findclient)
          setentetfacture(entet_data)
         

      
          let listlignes = []
          const valuelist = [...inputValue]

          lignesdata.forEach((item, index) => {
            const itemdata = {
              id: item.id,
              produit_code: item.produit_code,
              quantite: item.quantite,
              pu_ht: item.pu_ht,
              total_brut_ht: item.total_brut_ht,
              total_ht: item.total_ht,
              total_ht_remise:item.total_ht,
              total_net_ht: item.total_net_ht,
              montant_tva: item.montant_tva,
              pu_ttc: item.pu_ttc,
              taux_tva: item.taux_tva,
              prix_achat_ht: item.prix_achat_ht,
              prix_achat_ttc: item.prix_achat_ttc,
              taux_tva: item.taux_tva,
              num_serie: item.num_serie !== null ? item.num_serie.split(',') : [],
              remise: item.remise,
              remise_client: entetdata.remise_pied,
              email: item.email,
              libelleProduit: item.libelleProduit,
              actionRemise: item.actionRemise,
              suivieStock: item.suivieStock,
              code_abonnement: item.code_abonnement,
              exonere: dataFournisseur?.find(c => c.value === entetdata.client_code)?.exonere || '',
              has_num_serie: products?.find(p => p.code === item.produit_code)?.num_serie,

            }
            valuelist[index] = item.num_serie !== null ? item.num_serie.split(',') : [];

            listlignes.push(itemdata)
          });
          setInputList(listlignes)
          setInputValue(valuelist)

        }
      })
    /*getEnteteByCode(props.match.params.id).then((res) => {

      const entet_data = {
        code: res.data.code,
        code_commande: res.data.code_commande,
        facture_code: res.data.facture_code,
        numero_bl: res.data.numero_bl,
        user_code: res.data.user_code,
        client_code: res.data.client_code,
        client_nom: res.data.client_nom,
        timbre: res.data.timbre,
        adresse: res.data.adresse,
        commentaire: res.data.commentaire,
        code_abonnement: res.data.code_abonnement,
        sended_email: res.data.sended_email,
        net_a_payer: res.data.net_a_payer,
        montant_total_ttc: res.data.montant_total_ttc,
        montant_total_tva: res.data.montant_total_tva,
        net_a_payer_ht: res.data.net_a_payer_ht,
        montant_total_ht: res.data.montant_total_ht,
        type: 'bl',
        document: 'vente',
        societe_code: localStorage.getItem('code_generated'),
        commercial_code: res.data.commercial_code,
        date: res.data.date.split('T')[0],
        retenue_source: res.data.retenue_source,
        remise_pied: res.data.remise_pied,
        remise_action: res.data.remise_action,
        date_echeance: res.data.date_echeance !== null ? res.data.date_echeance.split('T')[0] : null,
        commentaire: res.data.commentaire,
        code_piece_pre: res.data.code_piece_pre,
        exonere: dataFournisseur?.find(c => c.value === res.data.client_code)?.exonere || '',
      }
      setentetfacture(entet_data)
      getLigneByCode(entet_data.code).then((res) => {
        const listinputs = res.data;
       listinputs.map(element => {

          return (
            element.num_serie=  element.num_serie!== null ? element.num_serie.split(',') :null
          )

        });
        console.log(listinputs)

        setInputList(listinputs)
      });
      getClientByCodeId(res.data.client_code).then((res) => {
        setdeviseclient({
          libelle: res.data.libelle,
          code_devise: res.data.code_devise,
          fraction: res.data.fraction,
          devison: res.data.devison
        })
      });


    });*/}
    else {
      getLastfactureVenteID().then((res) => {
        if (res?.data?.data) {
          if (res.data.data.last_code) {

            const lastsouche = res.data.data.last_code.split(res.data.data.seq).pop();
            const num = parseInt(lastsouche) + 1;
            if (isNaN(num)) {
              setentetfacture(prev => ({
                ...prev,
                facture_code: `${res.data.data.seq}1`

              }));
            } else {
              setentetfacture(prev => ({
                ...prev,
                facture_code: `${res.data.data.seq}${parseInt(lastsouche) + 1}`,

              }));
            }

          }
          else {
            setentetfacture(prev => ({
              ...prev,
              facture_code: `${res.data.data.seq}1`

            }));
          }
        }
      })

    }
  }, [products, dataFournisseur,societe])

  const handlechangeentet = (filed, value) => {

    if (filed === 'client_code') {
      setentetfacture(prev => ({
        ...prev,
        client_code: value.value,
        remise_pied: value.remise_client,
        client_nom: value.label,
        client_mf:value.matricule_fiscale,
        client_mobile:value.mobile,
        client_adresse_facturation: value.adresse_facturation,
        client_email:value.email,
        exonere: value.exonere,
        retenue_source: value.rs_id,
      }));
      setclient(value)
      const deviseid = value.devise_id 
  
      setdeviseclient(devises.find(item => item.id === deviseid))
      const updatedInputList = inputList.map(item => {
        const updatedItem = { ...item };
        updatedItem.remise_client = value.remise_client;
      
        let remiseclient = calculateRemise(
          parseFloat(updatedItem.total_ht_remise),
          value.remise_client,
          entetfacture.remise_action
        );
        

        updatedItem.total_net_ht = parseFloat(remiseclient);
        updatedItem.email = value.email;
        return updatedItem;
      });

      // Mettez à jour l'état avec le nouveau tableau
      setInputList(updatedInputList);

    }
     else {
      setentetfacture(prev => ({
        ...prev,
        [filed]: ((filed === 'remise_pied' && value !== '')|| (filed !=='remise_pied')) ? value : 0
      }));
      if (filed === 'remise_action') {
        const updatedInputList = inputList.map(item => {
          const updatedItem = { ...item };
          let remiseclient = calculateRemise(
            updatedItem.total_ht_remise,
            updatedItem.remise_client,
            value
          );

          updatedItem.total_net_ht = parseFloat(remiseclient);
          return updatedItem;
        });
        setInputList(updatedInputList);

      } 
      else if (filed === 'remise_pied') {
        const updatedInputList = inputList.map(item => {
          const updatedItem = { ...item };
          updatedItem.remise_client = value !== '' ? value :0;

          let remiseclient = calculateRemise(
            updatedItem.total_ht_remise,
            parseFloat( updatedItem.remise_client ),
            entetfacture.remise_action
          );

          updatedItem.total_net_ht = parseFloat(remiseclient);
          return updatedItem;
        });
        setInputList(updatedInputList);

      }

    }

  }


  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...inputList];
    if ((value < list[index]["quantite"]) && (list[index]["quantite"] === list[index]["num_serie"].length)) {
      //console.log(list[index]["num_serie"])

      list[index]["num_serie"].pop()
      // console.log(list[index]["num_serie"])
    }
    if (name === 'product') {
      let position = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[position].text;

      list[index]["produit_code"] = products[position - 1].code;
      list[index]["libelleProduit"] = products[position - 1].libelle;
      list[index]["pu_ht"] = products[position - 1].prix_vente_ht_1;
      list[index]["pu_ttc"] = products[position - 1].prix_vente_ttc_1;
      list[index]["taux_tva"] = entetfacture.exonere !== 1 ? products[position - 1].tva : 0;
      list[index]["suivieStock"] = products[position - 1].suivie_stock;
      list[index]["prix_achat_ht"] = products[position - 1].prix_achat_ht;
      list[index]["prix_achat_ttc"] = products[position - 1].prix_achat_ttc;
      list[index]["taux_tva"] = entetfacture.exonere !== 1 ? products[position - 1].tva : 0;
      list[index]["has_num_serie"] = products[position - 1].num_serie;
      list[index]['total_ht'] = products[position - 1].prix_vente_ht_1* list[index]["quantite"] ;
      list[index]['total_ht_remise'] = products[position - 1].prix_vente_ht_1* list[index]["quantite"] ;

    }
    if(name ==='libelleProduit'){
            list[index]["libelleProduit"] =value;

    }
    else {
      if (name === "actionRemise") {
        list[index][name] = value;

      } else if(name==='remise'){
        list[index][name] = value!== '' ? parseFloat(value) : 0;

      }else if(name==='quantite'){
       
         list[index][name] = parseFloat(value);
         list[index]['total_ht_remise'] =  parseFloat(value)* list[index]["pu_ht"] ;

          list[index]['total_ht'] = parseFloat(value)* list[index]["pu_ht"] ;

      }else if(name==='pu_ht'){
        list[index][name] = parseFloat(value);
     
         list[index]['total_ht'] = parseFloat(value)* list[index]["quantite"] ;
  
     }
        else {
        list[index][name] = parseFloat(value);

      }
      // Mettre à jour les champs dans la liste

 
    }

    let sum = calculateRemise(
      list[index]['total_ht'],
      list[index]["remise"],
      list[index]["actionRemise"]
    );
    list[index]['total'] = MultiplicateNumber(list[index]["pu_ttc"], list[index]["quantite"]);
    list[index]['total_ht_remise']=parseFloat(sum).toFixed(deviseclient?.devision || 3);
    list[index]['total_brut_ht'] = MultiplicateNumber(list[index]["pu_ht"], list[index]["quantite"]);
   // list[index]['total_ht'] = parseFloat(sum.toFixed(deviseclient?.devision || 3))
    let remiseclient = calculateRemise(
      sum,
      entetfacture.remise_pied,
      entetfacture.remise_action
    );
    list[index]['total_net_ht'] = parseFloat(remiseclient).toFixed(deviseclient?.devision || 3);

    //list[index]['total_net_ht'] = parseFloat((sum * ((100 - list[index]["remise_client"]) / 100)).toFixed(deviseclient?.devision || 3))
    setInputList(list);

  }

  const handleAddClick = () => {
    if (entetfacture.code_abonnement === '' || entetfacture.code_abonnement === null) {
      setInputList([
        ...inputList,
        {
          id: '',
          produit_code: "",
          quantite: 0,
          pu_ht: 0,
          total_brut_ht: 0,
          total_ht: 0,
          total_net_ht: 0,
          montant_tva: 0,
          pu_ttc: 0,
          taux_tva: 0,
          prix_achat_ht: 0,
          prix_achat_ttc: 0,
          taux_tva: 0,
          num_serie: [],
          remise: 0,
          remise_client: 0,
          email: '',
          libelleProduit: '',
          actionRemise: '1',
          suivieStock: '',
          code_abonnement: '',
        },
      ]);
    } else {
      toast.warning('vous ne pouvez pas modifier cette facture ')
    }

  };

  const handlenumseriechange = (e, index, index_num) => {
    //const value = inputValue[index][index_num]
    const value = e.label

    const list = [...inputList];
    let test = 1;


    if (list[index]["num_serie"].includes(value)) {
      toast.warning("Ce numéro de série existe déjà dans l'entrée actuelle !");
      test = 0;
    } else {
      const listprodnumserie = products.find(item => item.code === list[index]["produit_code"]);

      if (listprodnumserie) {
        const checkvalueinlist = listprodnumserie.listnumseries.find(item => item.numero_serie === value);
        if (checkvalueinlist) {
          if (checkvalueinlist.document_sortie !== null) {
            toast.warning("Ce numéro de série a déjà été vendu !");
            test = 0;
          }
        } else {
          toast.warning("Ce numéro de série ne correspond à aucun numéro de série de ce produit !");
          test = 0;
        }
      }
    }
    if (test === 1) {
      // alert(test)

      // Remplacer la valeur à l'index donné
      list[index]["num_serie"][index_num] = value;

      // Filtrer les valeurs vides de la liste
      list[index]["num_serie"] = list[index]["num_serie"].filter(val => val !== undefined && val !== null && val !== "");
      // console.log(list[index]["num_serie"])
      setInputList(list);
    }

  }
  const handleRemoveClick = (index) => {
    if (entetfacture.code_abonnement === '' || entetfacture.code_abonnement === null) {
      const item = inputList[index]
      if (item.id !== '') {
        setlisttoremove(prev => [...prev, item.id])
      }
      const list = [...inputList];
      list.splice(index, 1);
      setInputList(list);
    } else {
      toast.warning('vous ne pouvez pas modiifer cette facture')
    }


  };

  const handleSaveEntete = () => {
    if (checkEmptyPrice_Quantity(inputList)) {
      toast.error(t("Veuillez ajouter quantité/prix"));
    } else if (entetfacture.client_code === '' || entetfacture.date === '') {
      toast.error(t("Veuillez entree tous les champs requis de l'entête"));

    } else {
      const newentet = { ...entetfacture }

      delete newentet.exonere;
      newentet.code = generateUniqueCode()

      const updatedInputList = inputList.map(item => {
        const updatedItem = { ...item };
        updatedItem.num_serie = updatedItem.num_serie.join(',')

        updatedItem.entetecommercial_code = newentet.code;
        updatedItem.montant_tva = updatedItem.total_net_ht * (updatedItem.taux_tva / 100)
        updatedItem.taux_tva = updatedItem.taux_tva

        delete updatedItem.exonere;
        delete updatedItem.has_num_serie;
        delete updatedItem.product;
        delete updatedItem.total_ht_remise;

        delete updatedItem.id;
        updatedItem.societe_code = localStorage.getItem('code_generated');
        return updatedItem;
      });
      const data = { entet: newentet, lignes: updatedInputList }

      AjoutEntete_ligne(data).then((res) => {
        if (res.status === 201) {
          history.push('/admin/facturevente')
        }
      }
      )
    }

  }

  const handleClick = () => {
    if (props.match.params.id) {
      handleupdateEntete()
    } else {
      handleSaveEntete()
    }


  }



  const handleupdateEntete = () => {
    if (checkEmptyPrice_Quantity(inputList)) {
      toast.error(t("Veuillez ajouter quantité/prix"));
    } else if (entetfacture.client_code === '' || entetfacture.date === '') {
      toast.error(t("Veuillez entree tous les champs requis de l'entête"));

    } else {
      const newentet = { ...entetfacture }

      delete newentet.exonere;

      const updatedInputList = inputList.map(item => {
        const updatedItem = { ...item };
        updatedItem.num_serie = updatedItem.num_serie.join(',')
        updatedItem.montant_tva = updatedItem.total_net_ht * (updatedItem.taux_tva / 100)
        updatedItem.taux_tva = updatedItem.taux_tva

        updatedItem.entetecommercial_code = newentet.code;

        delete updatedItem.exonere;
        delete updatedItem.has_num_serie;
        delete updatedItem.product;
        delete updatedItem.total_ht_remise;

        updatedItem.societe_code = localStorage.getItem('code_generated');
        return updatedItem;
      });
      const data = { entet: newentet, lignes: updatedInputList, remove: idtoremove }
     
      UpdateEntete_ligne(data).then((res) => {
        if (res.status === 200) {
          history.push('/admin/facturevente')
        }
      }
      )
    }
 
  }

  const modifdoneeclient=()=>{
    updateInfoFacture(entetfacture.facture_code).then(res=>{
      if(res.status===200){
        toast.success('Les données client ont été modifiées avec succès')
        const newdata = res.data.data 
        setentetfacture(prev => ({
          ...prev,
          client_nom : newdata.nom+""+newdata.prenom,
          client_adresse_facturation: newdata.adresse_facturation,
          client_email: newdata.email,
          client_mobile: newdata.mobile,
          client_mf: newdata.matricule_fiscale
         
  
        }));
      }
    })
  }
  return (
    <>
      <div className="page-header d-xl-flex d-block">
        <div className="page-leftheader">
          <h4 className="page-title">{t("Facture vente")}</h4>
        </div>
        <div className="page-rightheader ml-md-auto">
          <div className="align-items-end flex-wrap my-auto right-content breadcrumb-right">
            <div className="btn-list">
              <Link to="/admin/addclient" className="btn btn-primary mr-3">
                {t("Ajouter client")}
              </Link>
              <Link to="/admin/addproduct" className="btn btn-primary mr-3">
                {t("Ajouter produit")}
              </Link>
              {/* <button className="btn btn-light" data-toggle="tooltip" data-placement="top" title="E-mail">
                <i className="feather feather-mail" />
              </button>
              <button className="btn btn-light" data-placement="top" data-toggle="tooltip" title="Contact">
                <i className="feather feather-phone-call" />
              </button>
              <button className="btn btn-primary" data-placement="top" data-toggle="tooltip" title="Info">
                <i className="feather feather-info" />
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header  border-0">
            <h4 className="card-title">{t(" Facture de vente ")} Numéro : {entetfacture.facture_code}</h4>

          </div>
          <div className="card-body">
            <div className="content m-4">
              <ToastContainer />

              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    {/* <div className="" style={{width:'150px' ,marginLeft:'10px'}}>
                      <div className="form-group">
                        <label>
                          {t("code  ")} 
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="text"
                            value={entetfacture.code_fournisseur}
                            onChange={(e) => handlechangeentet('code_fournisseur', e.target.value)}
                          />
                        </div>
                      </div>
                    </div>*/}
                    <div className="" style={{ width: '400px', marginLeft: '30px' }}>
                      <div className="form-group">
                        <label>
                          {t("Client")} : {entetfacture.client_nom}
                          <span className="text-danger">*</span>
                        </label>
                        <ReactSelect options={dataFournisseur} onChange={(e) => handlechangeentet('client_code', e)} />
                      </div>
                    </div>
                   
                    {devises.length !== 0 && deviseclient &&
                      <div className="" style={{ width: '150px', marginLeft: '30px' }}>

                        <div className="form-group">
                          <label>
                            {t("devise")}
                            <span className="text-danger">*</span>
                          </label>
                          <ReactSelect
                            value={devises && devises.find(item => item.id === deviseclient.id)}
                            options={devises}
                            onChange={(e) => setdeviseclient(devises.find(item => item.id === e.id))}
                            formatOptionLabel={devise => (
                              <div className="country-option">
                                <img src={`${image_url}${devise.flag}`} style={{ marginRight: '5px', width: '20px', height: 'auto' }} alt="flag" />
                                <span>{devise.libelle}</span>
                              </div>
                            )}
                          />  </div>
                      </div>}
                          
                    {entetfacture.code_abonnement === '' || entetfacture.code_abonnement === null &&(
                      
                    <div style={{ width: '250px', marginLeft: '30px' }}>
                    <label>{t("Remise")}</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <select
                          value={entetfacture.actionRemise}
                          className="form-control btn btn-outline-secondary"
                          onChange={(e) => handlechangeentet('remise_action', e.target.value)}
                        >
                          <option value="1">{t("Pourcent")}</option>
                          <option value="2">{t("Montant")}</option>
                        </select>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        aria-label="Text input with dropdown button"
                        value={entetfacture.remise_pied}
                        onChange={(e) => handlechangeentet('remise_pied', e.target.value)}
                      />
                    </div>
                  </div>
                    )}
                    {(entetfacture.code_abonnement !== '' && entetfacture.code_abonnement !== null)  && (
                      <div className="col-sm-2">
                        <label>{t("Code Abonnement")}</label>
                        <small>{entetfacture.code_abonnement}</small>
                      </div>
                    )}

                    <div className="" style={{ width: '150px', marginLeft: '30px' }}>
                      <div className="form-group">
                        <label >{t("Numéro de commande")}</label>
                        <input
                          className="form-control"
                          type="text"
                          value={entetfacture.code_commande}
                          onChange={(e) => handlechangeentet('code_commande', e.target.value)}
                        />
                      </div>
                    </div>

                    {entetfacture.type_piece_pre === 'devis' &&
                      <div className="col-sm-2" >
                        <div className="form-group">
                          <label>
                            {t("Numéro de devis")}
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            value={entetfacture.code_piece_pre}
                            onChange={(e) => handlechangeentet('code_piece_pre', e.target.value)}
                          />
                        </div>
                      </div>}

                    {entetfacture.type_piece_pre === 'bl' &&
                      <div className=" " style={{ width: '150px', marginLeft: '30px' }}>
                        <label>
                          {t("Numéro de BL")}
                        </label>
                        <input className="form-control" type="text"
                          value={entetfacture.code_piece_pre}
                          onChange={(e) => handlechangeentet('code_piece_pre', e.target.value)}

                        />
                      </div>}
                    <div className="" style={{ width: '150px', marginLeft: '30px' }}>
                      <div className="form-group" >
                        <label>
                          {t("Date de facture")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={entetfacture.date}
                            onChange={(e) => handlechangeentet('date', e.target.value)}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '150px', marginLeft: '30px' }}>
                      <div className="form-group">
                        <label>
                          {t("Date d'echeance")}
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={entetfacture.date_echeance}
                            onChange={(e) => handlechangeentet('date_echeance', e.target.value)}

                          />
                        </div>
                      </div>
                    </div>

                  </div>


                  {/* <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date d'aujourd'hui")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={selectedDateToday}
                            onChange={(e) => setSelectedDateToday(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>*/}

                  {/*
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label>
                          {t("Date d'échéance")} <span className="text-danger">*</span>
                        </label>
                        <div className="cal-icon">
                          <input
                            className="form-control"
                            type="date"
                            format="yyyy-MM-dd"
                            value={selectedDateDeadline}
                            onChange={(e) => setSelectedDateDeadline(e.target.value)}
                          />
                        </div>
                      </div>
                  </div>*/}
                  {/* <div className="row" style={{ marginTop: 20 }}>
              {JSON.stringify(inputList)}
            </div> */}
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="table-responsive" id="mycustomtable">
                        <table className="table table-hover table-white">
                          <thead>
                            <tr>
                              <th>#</th>
                              {(entetfacture.code_abonnement === '' || entetfacture.code_abonnement === null) && <th>{t("Choisir produit")}</th>}
                              <th>{t("Libelle")}</th>
                              <th>{t("Quantité")}</th>
                              {/*<th>{t('numseries')}</th>*/}
                              <th>{t("Prix U.HT")}</th>
                              <th>{t("Total HT")}</th>

                              <th style={{ paddingLeft: '100px' }}>{t("Remise")} </th>
                              <th>{t("TVA")}%</th>

                              {/*<th>{t('Remise client')}</th>*/}
                              <th>{t('Net_HT')}</th>

                              <th> </th>
                            </tr>
                          </thead>
                          <tbody>
                            {inputList.map((x, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i}</td>
                                  {entetfacture.code_abonnemen}
                                  {entetfacture.code_abonnement === '' || entetfacture.code_abonnement === null &&
                                    <td style={{ minWidth: '200px', width: '200px', zIndex: 99 }}>

                                      <select disabled={entetfacture.client_code === ''} className="form-control" name='product'  value={x.produit_code} onChange={(e) => handleInputChange(e, i)}>
                                        <option value={"default"}>{t("Choisir produit")}</option>
                                        {products.map((option) => (
                                          <option key={option.id} value={option.code}>
                                            {option.libelle}
                                          </option>
                                        ))}
                                      </select>
                                    </td>}

                                  <td style={{ minWidth: 450, width: 450 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="libelleProduit"
                                      value={x.libelleProduit}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                    {
                                      x.has_num_serie === "1" ? (
                                        <td style={{ minWidth: 200, zIndex: 1000 }}>
                                          {x.quantite > 0 ? (


                                            Array.from({ length: x.quantite }, (_, j) => (
                                              <>
                                                <div style={{ width: 170, position: "relative", height: "44px" }}>
                                                  {/*<input
                                                    type="text"
                                                    name="num_serie"
                                                    onChange={(e) => {
                                                      const value = e.target.value
                                                      const updatedValue = [...inputValue];
                                                      if (!updatedValue[i]) {
                                                        updatedValue[i] = [];
                                                      }

                                                      updatedValue[i][j] = value;

                                                      setInputValue(updatedValue);
                                                    }}
                                                    onBlur={(e) => {
                                                      handlenumseriechange(e, i, j);
                                                    }}
                                                    value={inputValue[i] ? inputValue[i][j] : ''}

                                                  />*/}


                                                  <ReactSelect

                                                    options={products.find(item => item.code === x.produit_code).options}

                                                    value={products.find(item => item.code === x.produit_code).options?.find(item => item.label === x.num_serie[j]) ?products.find(item => item.code === x.produit_code).options?.find(item => item.value === x.num_serie[j]):''}


                                                    onChange={(e) => {
                                                      handlenumseriechange(e, i, j);

                                                    }}
                                                  />
                                                </div>
                                              </>
                                            ))
                                          ) : null}
                                        </td>
                                      ) : <td></td>
                                    }

                                  </td>
                                  <td style={{ minWidth: 100, width: 70 }}>
                                    <input className="form-control" type="number" name="quantite" value={x.quantite} onChange={(e) => handleInputChange(e, i)} />

                                  </td>


                                  <td style={{ minWidth: 150, width: 150 }}>
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="pu_ht"
                                      value={x.pu_ht}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                  </td>
                                  <td style={{ minWidth: 150, width: 150 }}>
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      name="totalHt"
                                      value={x.total_ht}
                                      onChange={(e) => handleInputChange(e, i)}
                                    />
                                  </td>
                               
                                  <td style={{ minWidth: 250, width: 200 }}>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <select
                                          defaultValue="1"
                                          className="form-control btn btn-outline-secondary"
                                          name="actionRemise"
                                          value={x.actionRemise}
                                          onChange={(e) => handleInputChange(e, i)}
                                        >
                                          <option value="1">{t("Pourcent")}</option>
                                          <option value="2">{t("Montant")}</option>
                                        </select>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        aria-label="Text input with dropdown button"
                                        name="remise"
                                        value={x.remise}
                                        onChange={(e) => handleInputChange(e, i)}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ minWidth: 90, width: 90 }}>
                                    <select className="form-control" value={x.taux_tva} name='taux_tva' onChange={(e) => handleInputChange(e, i)}>
                                      {listTva_societe.map(item => (
                                        <option value={item.taux}>
                                          {item.libelle}
                                        </option>))}
                                    </select>
                                  </td>
                                  {/* <td>
                                    <input className="form-control" readOnly type="text" value={x.remise_client + " %"} />

                                  </td>*/}
                                  <td>
                                    <input className="form-control" readOnly type="text" value={x.total_net_ht} style={{ minWidth: 150, width: 150 }} />

                                  </td>

                                  <td>
                                    {inputList.length - 1 === i && (
                                      <Link to="#" className="text-success font-18 mr-2" title="Add" onClick={handleAddClick}>
                                        <i className="fa fa-plus" />
                                      </Link>
                                    )}
                                    {inputList.length !== 1 && (
                                      <Link to="#" className="text-danger font-18" title="remove" onClick={() => handleRemoveClick(i)}>
                                        <i className="fa fa-minus" />
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody> 
                        </table>
                      </div>

                      <div className="row">
                        <div className="col-7">
                          <Tabletva data={listTva} />
                        </div>
                        <div className="col-5 " style={{ paddingLeft: '150px' }}>
                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Total brut")}</strong>

                            </div>
                            <div className="col-8">
                              <span>      {entetfacture.montant_total_ht}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                          {parseInt(entetfacture.remise_total) !== 0 && (
                            <div className="row">
                              <div className="col-4">
                                <strong>{t("Remise")}</strong>

                              </div>
                              <div className="col-8">
                                <span>      {entetfacture.remise_total}{' '}{deviseclient?.code_devise}</span>
                              </div>
                            </div>
                          )}

                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Net HT")}</strong>

                            </div>
                            <div className="col-8">
                              <span>      {entetfacture.net_a_payer_ht}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Montant  TVA")}</strong>

                            </div>
                            <div className="col-8">
                              <span>     {entetfacture.montant_total_tva}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Total  TTC")}</strong>

                            </div>
                            <div className="col-8">
                              <span>     {entetfacture.montant_total_ttc}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                          {(societe?.timbre_active === 1 || entetfacture.timbre !== 0) &&
                            <div className="row">
                              <div className="col-4">
                                <strong >{t("Timbre Fiscale")}</strong>

                              </div>
                              <div className="col-8">
                                <span>     {(entetfacture.timbre && entetfacture.timbre !== 0) ? entetfacture.timbre.toFixed(deviseclient?.devision || 3) : societe?.timbre_fiscale.toFixed(deviseclient?.devision || 3)}{' '}{deviseclient?.code_devise}</span>
                              </div>
                            </div>
                          }
                          <div className="row">
                            <div className="col-4">
                              <strong>{t("Net a payer")}</strong>

                            </div>
                            <div className="col-8">
                              <span>     {entetfacture.net_a_payer}{' '}{deviseclient?.code_devise}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{t("Ajouter note")}</label>
                            <textarea onChange={(e) => setNoteFacture(e.target.value)} className="form-control" value={entetfacture.commentaire} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row no-print mt-4">
                    <div className="col-12">
                      {nbSendedEmail > 0 && (
                        <span className="badge badge-danger-light mr-2">
                          <i className="fa fa-send text-danger" /> {t("Facture déja envoyée")} {nbSendedEmail} fois par email
                        </span>
                      )}

                      {inputList[0]?.quantite && (
                        <>
                          <button type="button" className="btn btn-primary float-right" onClick={handleClick}>
                            <i className="fa fa-credit-card" /> {t("Enregistrer")}
                          </button>

                          <button type="button" className="btn btn-info float-right mr-1" onClick={() => setShowResults(true)}>
                            <i className="fa fa-file-pdf-o" /> {t("Afficher facture")}
                          </button>


                        </>
                      )}
                        <button type="button" className="btn btn-info float-right mr-1" onClick={modifdoneeclient}>
                            <i className="fa fa-file-pdf-o" /> {t("modifier donnee client")}
                          </button>


                    </div>

                  </div>

                  {/* <button className="btn btn-primary" onClick={handleSaveEntete}>
            Enregister
          </button>
          <button className="btn btn-info">Afficher facture</button> */}

                  <div className="row mt-4">
                    <div className="col-12">
                      {showResults ? (
                        <>
                          <PDFViewer style={{ width: "100%", height: "800px" }}>
                            <Facture entetfacture={entetfacture} societe={societe} inputList={inputList} devise={deviseclient} t={t} listTva={listTva} client={client} />
                          </PDFViewer>
                        </>
                      ) : null}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default EditerFactureVente;

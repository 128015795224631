import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { NumberToLetter } from 'convertir-nombre-lettre';

const styles = StyleSheet.create({
  page: {
    padding: '50px',
    width: '21cm',
    minHeight: '29.7cm',
    margin: '1cm auto',


  },
  row: {
    flexDirection: "row",
    alignItems: "center"
  },
  cadre: {
    flex: 2,
    border: '2px solid black',
    padding: '10px'
  },
  image: {
    width: '20%',
  },
  titre: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black'
  },
  rib:{
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'black'
  },
  font: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: 'black'
  },
  table: {
    display: 'table',
    width: 'auto',
    marginTop: '20px',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    flexDirection: 'row',

  },
  tableCol: {
    width: '25%',
    fontSize: '10px',
    border: '1px solid black',
    height: 30, // Ajuster la hauteur ici
    alignItems: 'center', // Pour centrer le contenu verticalement
    justifyContent: 'center', // Pour centrer le contenu horizontalement
  },
  tableColRib: {
    width: '50%',
    fontSize: '10px',
    border: '1px solid black',
    height: 30, // Ajuster la hauteur ici
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableCol4: {
    width: '99%', // Each column takes up 25% of the row
    fontSize: '10px',
    border: '1px solid black '


  },
  tableCell: {

  },
  margin_top: {
    marginTop: '10px'
  },
  float_left: {
    float: 'left'
  }

});

const OrdreVirementPDF = ({ societe, mois, year, t, list }) => {
  const total = list?.reduce((acc, curr) => acc + parseFloat(curr?.net_a_payer), 0) || 0;
  const image_url = process.env.REACT_APP_API_URL;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src={image_url + societe?.logo} />

          <View style={styles.cadre}>
            <Text style={styles.titre}>{societe?.nom}</Text>
            <Text style={[styles.font, styles.marginTop]}>ADRESSE: {societe?.adresse}</Text>
            <Text style={styles.font}>MF: {societe?.matricule_fiscal}</Text>
            <Text style={styles.font}>TEL: {societe?.mobile}</Text>
            <Text style={styles.font}>MAIL: {societe?.email}</Text>
          </View>
          <Image style={styles.image} src={image_url + societe?.imageente} />
        </View>
        <View style={styles.margin_top}>
          <Text style={{ textAlign: 'right', fontSize: '10px' }}>Tunis le {moment(new Date()).format('DD/MM/YYYY')}</Text>

          <Text style={styles.font}>
            Messieurs, Madame ({societe?.banque}) {"\n"}
            Par le débit de notre compte n° {" "}
            <Text style={styles.rib} >{societe?.rib}</Text> {" "}
            ouvert dans nos livres, veuillez procéder au(x) virement(s) suivant(s) pour le(s) salaire(s) du mois {mois} {year}
          </Text>




        </View>
        <View style={styles.table}>
          <View style={[styles.tableRow, { backgroundColor: '#CDCECF' }]}>
            <View style={styles.tableCol}><Text>code</Text></View>
            <View style={styles.tableCol}><Text >C.I.N</Text></View>
            <View style={styles.tableCol}><Text >Nom & Prénom</Text></View>
            <View style={styles.tableColRib}><Text>RIB</Text></View>
            <View style={styles.tableCol}><Text >BANQUE</Text></View>
            <View style={styles.tableCol}><Text >Montant</Text></View>
          </View>
          {list.map((item, index) => (
            <View key={index} style={styles.tableRow}>
              <View style={styles.tableCol}><Text >{item?.matricule_user?.toString()?.padStart(4, "0")}</Text></View>
              <View style={styles.tableCol}><Text >{item.cin}</Text></View>
              <View style={styles.tableCol}><Text >{`${item.nom} ${item.prenom}`}</Text></View>
              <View style={styles.tableColRib}><Text >{item.rib}</Text></View>
              <View style={styles.tableCol}><Text>{item.bank}</Text></View>
              <View style={styles.tableCol}><Text >{item.net_a_payer}</Text></View>
            </View>
          ))}
          <View style={styles.tableRow}>

            <View style={styles.tableCol4}><Text >Montant Total à Virer</Text></View>

            <View style={styles.tableCol}><Text >{total}</Text></View>
          </View>
        </View>

        <View style={{ marginTop: '10px' }} >
          <Text style={styles.font} >Arreté le présent virement à la somme de {NumberToLetter(total)}</Text>

        </View>
        <Text style={{ textAlign: 'right', fontSize: '30px', fontSize: '10px' }}>Signature</Text>

        <Text style={{ textAlign: 'center', fontSize: '10px', marginRight: '30px' }}>Avec nos remerciements !</Text>



      </Page>
    </Document>
  );
};

export default OrdreVirementPDF;
